import React from "react";

export const CloseIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 18.5L18 6.5M6 6.5L18 18.5"
      stroke="#6F7A7D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownloadIcon = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.2852 13.5806L18.9583 16.9074V8.74984C18.9583 7.9751 18.2747 7.2915 17.5 7.2915C16.6797 7.2915 16.0417 7.9751 16.0417 8.74984V16.9074L12.6693 13.5806C12.3958 13.3071 12.0312 13.1248 11.6667 13.1248C11.2565 13.1248 10.8919 13.3071 10.6185 13.5806C10.026 14.1274 10.026 15.0845 10.6185 15.6313L16.4518 21.4647C16.9987 22.0571 17.9557 22.0571 18.5026 21.4647L24.3359 15.6313C24.9284 15.0845 24.9284 14.1274 24.3359 13.5806C23.7891 12.9881 22.832 12.9881 22.2852 13.5806ZM24.7917 24.7915H10.2083C9.38802 24.7915 8.75 25.4751 8.75 26.2498C8.75 27.0702 9.38802 27.7082 10.2083 27.7082H24.7917C25.5664 27.7082 26.25 27.0702 26.25 26.2498C26.25 25.4751 25.5664 24.7915 24.7917 24.7915Z"
        fill="#07A69C"
      />
    </svg>
  );
};

export const RecalculateIcon = () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7397 14.7681H27V14.7671M8.00105 25.3743V20.2319M8.00105 20.2319H13.2613M8 20.2319L11.3519 23.5108C12.4311 24.5658 13.7753 25.3244 15.2495 25.7105C16.7237 26.0966 18.2759 26.0965 19.75 25.7102C21.2241 25.3239 22.5682 24.565 23.6472 23.5099C24.7262 22.4548 25.502 21.1405 25.8967 19.6993M9.10327 15.3007C9.49796 13.8595 10.2738 12.5452 11.3528 11.4901C12.4318 10.435 13.7759 9.6761 15.25 9.2898C16.7241 8.90351 18.2763 8.9034 19.7505 9.28948C21.2247 9.67556 22.5689 10.4342 23.648 11.4892L27 14.7671M27 9.6257V14.766"
      stroke="#07A69C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
