import { API_URI } from "../../config";
import { checkError, requestOption } from "../api/request";
import { major_categoriesAction } from "./major_categories-slice";

const getMajorCategories = (warehouse_code) => {
  return async (dispatch) => {
    const { setMajor_categoryList } = major_categoriesAction;
    const query = `&warehouse_code=${warehouse_code}`;

    try {
      const { data } = await requestOption(`${API_URI}/admin/v1/major_category?${query}`, false, {
        refetch: false,
      });
      dispatch(setMajor_categoryList(data));
    } catch (error) {
      checkError(error);
    }
  };
};

export default getMajorCategories;
