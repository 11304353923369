import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  major_categoryList: [],
  isLoading: true,
};

const major_categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setMajor_categoryList(state, {payload}) {
      state.major_categoryList = payload;
    },
  },
});

export default major_categoriesSlice.reducer;
export const major_categoriesAction = major_categoriesSlice.actions;
export const major_categoriesState = (state) => state.major_categories;