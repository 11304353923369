import { startCase } from "lodash";
import ViewDetails from "./ViewDetails";
import React from "react";

export const columnDef = [
  // {
  //   title: "Instance",
  //   dataIndex: "name",
  //   key: "name",
  //   width: "100px",
  //   sorter: (a, b) => a.name.localeCompare(b.name),
  //   render: (data) => startCase(data),
  // },
  {
    width: "0.5rem",
  },
  {
    title: "MFC",
    dataIndex: "warehouse_code",
    key: "warehouse_code",
    width: "70px",
    render: (data) => data?.join(", "),
    sorter: (a, b) => a.warehouse_code.join(", ").localeCompare(b.warehouse_code.join(", ")),
  },
  {
    title: "Top 100",
    dataIndex: "is_top100",
    key: "is_top100",
    width: "70px",
    render: (data) => (+data === 1 ? "Enabled" : "Disabled"),
    sorter: (a, b) => a.is_top100 - b.is_top100,
  },
  {
    title: "Vendor",
    dataIndex: "vendor_name",
    key: "vendor_name",
    width: "100px",
    render: (data) => data?.join(", "),
    sorter: (a, b) => a.vendor_name.join(", ").localeCompare(b.vendor_name.join(", ")),
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    width: "100px",
    render: (data) => (data || null)?.join(", "),
    sorter: (a, b) => (a.category || null)?.join(", ").localeCompare((b.category || null)?.join(", ")),
  },
  {
    title: "Planner",
    dataIndex: "planner",
    key: "planner",
    width: "100px",
    render: (o) => o,
    sorter: (a, b) => a.planner.localeCompare(b.planner),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "100px",
    render: (o) => startCase(o),
    sorter: (a, b) => a?.status.localeCompare(b?.status),
  },
  {
    title: "Action",
    key: "action",
    width: "100px",
    render: (data) => <ViewDetails row={data} />,
  },
];
