import { createSlice } from "@reduxjs/toolkit";
import { capitalize } from "lodash";

const initialState = {
  simulationList: [],
  activeSimulation: {},
  simulationSkus: [],
  simulationSkusPage: 1,
  simulationSkusTotal: null,
  currentSimulationID: null,
  finalizedSimulation: false,
  isLoading: false,
};

const simulationSlice = createSlice({
  name: "simulation",
  initialState,
  reducers: {
    setSimulationRecord(state, { payload }) {
      state.simulationRecord = payload;
    },
    setActiveSimulation(state, { payload }) {
      // console.log(payload);
      state.activeSimulation = payload;
    },
    setFinalizedSimulation(state, { payload }) {
      state.finalizedSimulation = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setSimulationSkus(state, { payload }) {
      state.simulationSkus = payload.data.map((d) => ({
        ...d,
        planning_status: capitalize(d.planning_status || "To do"),
      }));
      state.simulationSkusTotal = payload.total_count;
      state.simulationSkusPage = payload.page;
    },
    setCurrentSimulationID(state, { payload }) {
      state.currentSimulationID = payload;
    },
  },
});

export default simulationSlice.reducer;
export const simulationAction = simulationSlice.actions;
export const simulationState = (state) => state.simulation;
