import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector, useDispatch } from "react-redux";
import { simulationState } from "../../store/simulation/simulation-slice";
import { startCase, flatMap } from "lodash";
import { fetchFinalizePlan, getAllSimulationSKU, getSkuBySimulation } from "../../store/sku/sku-actions";
import { uiAction } from "../../store/ui/ui-slice";
import { useParams } from "react-router-dom";
import { datatableAction, datatableState } from "../../store/datatable/datatable-slice";
import { Spinner } from "../atoms/ProgressBar";
import { skuAction, skuState } from "../../store/sku/sku-slice";
import ModalBox from "../atoms/ModalBox";
import DownloadSimulation from "../molecules/Modals/DownloadSimulation";
import { Col, Input, Row, Tooltip } from "antd";
import { GsButton } from "../atoms/customForm";
import { useModal } from "../../utils/useModal";
import Instance from "../organisms/instance_today/Instance";
import sendNotification from "../../utils/sendNotification";
import { getRSCupdate } from "../../store/forecast/forecast-actions";

const InstancePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { modal, showModal } = useModal(false);
  const { searchSKU, searchableSkuList } = useSelector(skuState);
  const { setPreviousPage } = uiAction;
  const { isLoading } = useSelector(skuState);
  const { rowsPerPage, currentPage } = useSelector(datatableState);
  const { selectedItem } = useSelector(datatableState);
  const {
    activeSimulation: { simulation_name, simulation_id, mfc },
  } = useSelector(simulationState);

  useLayoutEffect(() => {
    dispatch(getSkuBySimulation(simulation_id, currentPage, rowsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);

  const onDisplayPlan = async () => {
    await dispatch(getRSCupdate({ warehouse: mfc }));

    const allSelectedItem = flatMap(Object.values(selectedItem));
    if (!allSelectedItem.length)
      return sendNotification("No Selected SKU's", "Select SKU's to plan", "error");

    dispatch(datatableAction.pageReset());
    dispatch(setPreviousPage(`/supply_plan/${id}`));
    history.push("/supply_planning");
  };

  const onFinalizePlan = () => {
    const onSuccess = () => history.push("/home");
    dispatch(fetchFinalizePlan(simulation_id, onSuccess));
  };

  !simulation_name && history.push("/home");

  return (
    <>
      <Spinner show={isLoading} />
      <ModalBox
        showModal={modal}
        setShowModal={showModal}
        content={<DownloadSimulation onSuccess={showModal} />}
      />
      <Row justify="space-between" align="center" style={{ paddingBottom: "1rem" }}>
        <Col className="tx700" style={{ paddingTop: "0.25rem" }}>
          <span className="tx700 pointer" onClick={() => history.push("/home")}>
            <Tooltip title="Go back" placement="right">
              {startCase(simulation_name)}
            </Tooltip>
          </span>
        </Col>

        <Col flex="auto">
          <Row justify="end">
            <Col flex="150px" style={{ paddingRight: "1.25rem" }}>
              <Input
                style={{ padding: "0.7rem", width: "200px" }}
                placeholder="Search SKU Code/Name"
                value={searchSKU}
                onChange={(e) => dispatch(skuAction.setSearchSku(e.target.value))}
                onFocus={() => !searchableSkuList.length && dispatch(getAllSimulationSKU(simulation_id))}
              />
            </Col>
            <Col flex="100px">
              <GsButton
                flex="100px"
                label="Download Simulation"
                style={{ padding: "0.65rem", marginRight: "1rem" }}
                onClick={() => showModal(!modal)}
              />
            </Col>
            <Col flex="100px">
              <GsButton
                label="Finalize Plan"
                style={{ padding: "0.65rem" }}
                type="primary"
                onClick={onFinalizePlan}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Instance />

      <Row justify="end">
        <Col>
          <GsButton
            label="Display Plan"
            onClick={onDisplayPlan}
            type="primary"
            style={{ padding: "0.65rem", zIndex: 2 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default InstancePage;
