import { startCase } from "lodash";

export const columnDef = [
  {
    width: "0.5rem",
  },
  {
    title: "Warehouse",
    dataIndex: "warehouse_code",
    key: "warehouse_code",
    // width: "100px",
    sorter: (a, b) => a.warehouse_code.localeCompare(b.warehouse_code),
    render: (data) => startCase(data),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    // width: "100px",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (data) => startCase(data),
  },
  {
    title: "Last updated",
    dataIndex: "last_updated",
    key: "last_updated",
    // width: "100px",
    render: (o) => o,
    sorter: (a, b) => a.last_updated.localeCompare(b.last_updated),
  },
  // {
  //   title: "Action",
  //   key: "action",
  //   width: "100px",
  //   render: (data) => <ViewDetails row={data} />,
  // },
];
