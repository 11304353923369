import React from "react";
import { Redirect, Switch } from "react-router-dom";
import "jsonminify";
import AuthPage from "./components/pages/AuthPage";
import { GuestRoute, PrivateRoute } from "./components/pages/PrivateRoute";
import SimulationPage from "./components/pages/SimulationPage";
import InstancePage from "./components/pages/InstancePage";
import ForecastPage from "./components/pages/ForecastPage";
import AddSimulationPage from "./components/pages/AddSimulationPage";
import ManageSkuPage from "./components/pages/ManageSkuPage";
import ManageVendor from "./components/organisms/config_management/vendor/ManageVendor";
import ManageAccounts from "./components/organisms/config_management/account/ManageAccount";
import ExplorePage from "./components/pages/ExplorePage";
import SyncStatusPage from "./components/pages/SyncStatusPage";

function App() {
  return (
    <Switch>
      <GuestRoute exact path="/login" component={AuthPage} />
      <PrivateRoute exact path="/home" component={SimulationPage} nav="home" />
      <PrivateRoute exact path="/explore" component={ExplorePage} nav="explore" />
      <PrivateRoute exact path="/sync_status" component={SyncStatusPage} nav="explore" />
      <PrivateRoute exact path="/supply_plan/:id" component={InstancePage} nav="home" />
      <PrivateRoute exact path="/supply_plan" component={AddSimulationPage} nav="home" />
      <PrivateRoute exact path="/supply_planning" component={ForecastPage} nav="home" />
      {/* CONFIG MANAGEMENT */}
      <PrivateRoute exact path="/manage_sku" component={ManageSkuPage} nav="configs" />
      <PrivateRoute exact path="/manage_vendors" component={ManageVendor} nav="configs" />
      <PrivateRoute exact path="/manage_accounts" component={ManageAccounts} nav="configs" />
      <Redirect path="*" to="/home" />
    </Switch>
  );
}

export default App;
