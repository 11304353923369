import { lowerCase } from "lodash";

export const columnDef = [
  {
    title: "Source",
    dataIndex: "source_type",
    width: "100px",
    sorter: (a, b) => a.source_type.localeCompare(b.source_type),
  },
  {
    title: "Vendor",
    dataIndex: "vendor_code",
    width: "100px",
    sorter: (a, b) => a.vendor_code.localeCompare(b.vendor_code),
  },
  {
    title: "Major Category",
    dataIndex: "mega_category",
    width: "100px",
    sorter: (a, b) => a.mega_category.localeCompare(b.mega_category),
  },
  {
    title: "Category",
    dataIndex: "category",
    width: "150px",
    sorter: (a, b) => a.category.localeCompare(b.category),
  },
  // {
  //   title: "SKU Rank",
  //   dataIndex: "rank",
  //   sorter: (a, b) => a.rank.localeCompare(b.rank),
  // },
  {
    title: "SKU Rank",
    dataIndex: "rank_gmv",
    width: "100px",
    sorter: (a, b) => a.rank_gmv - b.rank_gmv,
  },
  {
    title: "Item Code",
    dataIndex: "sku_code",
    width: "100px",
    sorter: (a, b) => a.sku_code.localeCompare(b.sku_code),
  },
  {
    title: "Sku Name",
    dataIndex: "name",
    width: "150px",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Status",
    dataIndex: "planning_status",
    width: "100px",
    sorter: (a, b) => a.planning_status.localeCompare(b.planning_status),
    render: (stat) => (lowerCase(stat) === "finalized" ? "Planned" : "To-do"),
  },
];
