import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Typography ,Form} from 'antd';
import React, { useState } from 'react';
const { Option } = Select;
let index = 0;

const DynamicSelect = ({ name, label, rules, options }) => {
    ;
    const [items, setItems] = useState(options);
    const [newName, setName] = useState('');

    const onNameChange = (event) => {
        ;
        setName(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, newName || `New item ${index++}`]);
        setName('');
    };

    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
            validateTrigger={['onChange', 'onBlur']}
        >
            <Select
                style={{
                    width: 300,
                }}
                placeholder=""
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider
                            style={{
                                margin: '8px 0',
                            }}
                        />
                        <Space
                            align="center"
                            style={{
                                padding: '0 8px 4px',
                            }}
                        >
                            <Input placeholder="Please enter item" value={newName} onChange={onNameChange} />
                            <Typography.Link
                                onClick={addItem}
                                style={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <PlusOutlined /> Add item
                            </Typography.Link>
                        </Space>
                    </>
                )}
            >
                {items.map((item) => (
                    <Option key={item}>{item}</Option>
                ))}
            </Select></Form.Item>
    );
};

export default DynamicSelect;
