import { Col } from "antd";
import React from "react";
import AuthForm from '../organisms/AuthForm';

const AuthPage = () => {
  return (
    <Col height="90vh">
      <AuthForm />
    </Col>
  );
};

export default AuthPage;
