import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // selectedItem: { 1: [2304] },
  selectedItem: {},
  totalCount: 1,
  rowsPerPage: 100,
  rowsPerPageOptions: [5, 10, 25, 50, 100],
  currentPage: 1,
  isLoading: true,
};

const datatableSlice = createSlice({
  name: "datatable",
  initialState,
  reducers: {
    setSelectedItem(state, { payload }) {
      // console.log(payload);
      state.selectedItem = payload;
    },
    setTotalCount(state, { payload }) {
      state.totalCount = payload;
    },
    setRowsPerPage(state, { payload }) {
      state.rowsPerPage = payload;
      state.currentPage = 0;
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    pageReset(state) {
      state.totalCount = 1;
      state.rowsPerPage = 100;
      state.currentPage = 1;
    },
  },
});

export default datatableSlice.reducer;
export const datatableAction = datatableSlice.actions;
export const datatableState = (state) => state.datatable;
