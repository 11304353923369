import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addTableStyle, getRowStyle, recalculatePlan } from "../../../store/forecast/forecast-actions";
import { updateUom } from "../../../store/forecast/forecast-actions";
import { forecastAction, forecastState } from "../../../store/forecast/forecast-slice";
import { omitBy, isNil, omit, forEach, pickBy, keys, join } from "lodash";
import { format } from "date-fns";
import { RecalculateIcon } from "../../../assets/icons";
import { GsButton } from "../../atoms/customForm";
import { Col, Row } from "antd";
import sendNotification from "../../../utils/sendNotification";
import { ProgressBar } from "../../atoms/ProgressBar";
import { EditOutlined } from "@ant-design/icons";
import { transformData } from "../../../store/forecast/helperFunc";

const SkuForecast = ({ data }) => {
  const dispatch = useDispatch();
  const { setRevisionStatus } = forecastAction;
  const { revisionStatus, forecastColumn, UoM } = useSelector(forecastState);
  const [rowData, setRowData] = useState(Object.values(transformData(data)));
  const [rowDataInit, setRowDataInit] = useState();
  const [column, setColumn] = useState(forecastColumn);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const { cbm_per_case = 1, case_config = 1, sku_code, updated_at, gs_price_per_piece } = data[0];
  const { warehouse_code, vendor_name, vendor_code, sku_id, name, planning_status } = data[0];
  const uom =
    UoM === "case"
      ? 1 / case_config
      : UoM === "cbm"
      ? cbm_per_case / case_config
      : UoM === "peso"
      ? gs_price_per_piece
      : 1;

  useLayoutEffect(() => {
    // const revisionMode = planning_status === 'FINALIZED' && editMode
    // console.log('revisionMode', revisionMode);
    // addTableStyle(column, revisionMode).then((x) => setColumn(x));
    const transform = Object.values(transformData(data));
    setRowData(updateUom(transform, uom, gs_price_per_piece, UoM === "cbm"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const revisionMode = planning_status === "FINALIZED" && editMode;
    addTableStyle(column, revisionMode, vendor_code, warehouse_code).then((x) => setColumn(x));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    if (rowDataInit) {
      const updateMetric = updateUom(rowDataInit, uom, gs_price_per_piece, UoM === "cbm");
      setRowData(updateMetric);
    } else {
      setRowDataInit(Object.values(transformData(data)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uom, UoM]);

  const onCellValueChanged = () => {
    const demandAdjustment = omitBy(
      rowData.find((x) => x.key_figure.match(/demand adjustment/i)),
      isNil
    );

    const supplyAdjustment = omitBy(
      rowData.find((x) => x.key_figure.match(/supply adjustment/i)),
      isNil
    );

    const isOnRevisionMode = Object.values(omit({ ...supplyAdjustment, ...demandAdjustment }, ["key", "key_figure"]))
      .length;

    dispatch(setRevisionStatus({ [sku_code]: isOnRevisionMode }));
  };

  const onRecalculate = async () => {
    const demand_adjustment = omit(
      omitBy(
        rowData.find((x) => x.key_figure.match(/demand adjustment/i)),
        isNil
      ),
      ["key", "key_figure"]
    );
    const supply_adjustment = omit(
      omitBy(
        rowData.find((x) => x.key_figure.match(/supply adjustment/i)),
        isNil
      ),
      ["key", "key_figure"]
    );

    const calc_adjustment = () => {
      const result = {};

      forEach(demand_adjustment, function(value, key) {
        const date = format(new Date(key), "yyyy-MM-dd");
        const id = data.find((s) => s.date === date)?.id;
        result[key] = {
          date,
          id,
          demand_adjustment: +value / uom,
          supply_adjustment: 0,
        };
      });
      forEach(supply_adjustment, function(value, key) {
        const date = format(new Date(key), "yyyy-MM-dd");
        const id = data.find((s) => s.date === date)?.id;
        const hasRecord = result[key];
        if (hasRecord) {
          result[key] = {
            ...result[key],
            id,
            supply_adjustment: +value / uom,
          };
        } else {
          result[key] = {
            date,
            id,
            supply_adjustment: +value / uom,
            demand_adjustment: 0,
          };
        }
      });
      return Object.values(result);
    };

    const payloadAdjustment = calc_adjustment();
    const activeRevision = join(keys(pickBy(revisionStatus, (value) => !!value)));

    if (!activeRevision?.length) {
      return sendNotification("No changes to recalculate.", "No changes made for this SKU", "error");
    }

    setLoading(true);
    const payload = {
      warehouse_code,
      sku_id: [sku_id],
      data: payloadAdjustment,
    };

    const onComplete = () => setLoading(false);
    const result = await recalculatePlan(payload, onComplete);
    setRowDataInit(result);

    //TEMP
    dispatch(setRevisionStatus({ [sku_code]: false }));
    const newArr = updateUom(result, uom, gs_price_per_piece) || [];
    setRowData(newArr);
  };

  return (
    <>
      <Row justify="space-between" align="middle" style={{ paddingTop: "1rem" }}>
        <Row style={{ paddingTop: "1.5rem" }}>
          <Col className="tx600 tx125">{`SKU ${sku_code}: ${name}`}</Col>
          <Col className="c3 tx90" span={24}>{`${warehouse_code} - ${vendor_name}`}</Col>
        </Row>
        <Row>
          {planning_status === "FINALIZED" && (
            <GsButton
              variant="text"
              p="1rem 0"
              style={{ paddingTop: "0.25rem", marginRight: "1rem" }}
              size="small"
              tooltip="Edit / Modify planed SKU"
              label={
                <EditOutlined style={{ width: "30px", fontSize: "20px", color: editMode ? "inherit" : "#f77d55" }} />
              }
              onClick={() => setEditMode(!editMode)}
            />
          )}
          <GsButton
            variant="text"
            p="1rem 0"
            style={{ paddingTop: "0.25rem" }}
            size="small"
            tooltip="Save & Recalculate"
            label={<RecalculateIcon />}
            onClick={onRecalculate}
          />
        </Row>
      </Row>

      <Row justify="end" className="c3">
        <b className="c1">Status: {planning_status === "FINALIZED" ? "Planned" : "Todo"}</b>
      </Row>
      <Row justify="end" className="c3">
        <i>Last update: {format(new Date(updated_at), "dd-MMM-yy HH:mmaaa")}</i>
      </Row>

      <AgGridReact
        columnDefs={column}
        rowData={!rowData.length ? [{ key_figure: "No record found" }] : rowData}
        onCellValueChanged={onCellValueChanged}
        enableCellChangeFlash={true}
        enableColResize={true}
        enableSorting={true}
        rowHeight={30}
        getRowStyle={getRowStyle}
        groupUseEntireRow={true}
        containerStyle={{ height: (rowData.length ? 2.3 * rowData.length : 13) + "rem" }}
        className={`ag-theme-balham ${planning_status === "FINALIZED" ? "planned" : "todo"}`}
      />
      <ProgressBar show={loading} />
    </>
  );
};

export default SkuForecast;
