import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { datatableState } from "../../../store/datatable/datatable-slice";
import getSimulationData from "../../../store/simulation/simulation-actions";
import { simulationState } from "../../../store/simulation/simulation-slice";
import { EditableTable } from "../../molecules";
import { columnDef } from "./tableOption";
import { authState } from "../../../store/auth/auth-slice";

const SimulationList = () => {
  const dispatch = useDispatch();
  const { simulationRecord } = useSelector(simulationState);
  const { rowsPerPage, currentPage } = useSelector(datatableState);
  const { token } = useSelector(authState);

  useEffect(() => {
    token && dispatch(getSimulationData(currentPage, 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);

  return (
    <>
      <EditableTable
        rowKey="id"
        rowClassName={() => "editable-row"}
        columns={columnDef}
        // loading={isLoading}
        dataSource={simulationRecord}
        size="small"
        scroll={{ x: "500px", y: "70vh" }}
        pagination={false}
      />
    </>
  );
};

export default SimulationList;
