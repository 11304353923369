import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { SideNav } from "../../molecules";
import services from "../../../services";

const { Sider, Content, Footer } = Layout;

const getServiceInfo = (serviceCode) => {
  // console.log(services,serviceCode);
  return services.find((service) => service.code === serviceCode);
};

function Admin({ sidenav = "iam", children }) {
  const service = getServiceInfo(sidenav);
  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, onNavCollapse] = useState(width <= 500 ? true : false);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth <= 500) {
      onNavCollapse(true);
    } else {
      onNavCollapse(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Layout id="admin-template" style={{ maxHeight: "100vh" }}>
      <Layout>
        <Sider theme="light" collapsed={collapsed} collapsedWidth={60}>
          <SideNav sections={service.nav} />
        </Sider>
        <Layout>
          <Content className="admin-content" style={{ padding: "2rem", marginBottom: "2rem", overflowY: "auto" }}>
            {children}
          </Content>
          <Footer className="app-footer">MFC Auto Order © GrowSari 2023</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Admin;
