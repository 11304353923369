import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableTable } from "../../molecules";
import { columnDef } from "./tableOption";
import { sync_statusState } from "../../../store/sync_status/sync_status-slice";
import { getSyncStatus } from "../../../store/sync_status/sync_status-actions";
import { Col } from "antd";
import { warehouseState } from "../../../store/warehouse/warehouse-slice";

const SyncStatusList = () => {
  const dispatch = useDispatch();
  const { sync_statusList } = useSelector(sync_statusState);
  const { warehouseList } = useSelector(warehouseState);

  useEffect(() => {
    warehouseList.length && dispatch(getSyncStatus(warehouseList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseList]);

  return (
    <>
      <Col style={{ paddingTop: "1rem" }}>
        <EditableTable
          rowKey="id"
          rowClassName={() => "editable-row"}
          columns={columnDef}
          // loading={isLoading}
          dataSource={sync_statusList}
          size="middle"
          scroll={{ x: "500px", y: "70vh" }}
          pagination={false}
        />
      </Col>
    </>
  );
};

export default SyncStatusList;
