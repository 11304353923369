import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth-slice";
import formSlice from "./form/form-slice";
import uiSlice from "./ui/ui-slice";
import vendorSlice from "./vendors/vendor-slice";
import simulationSlice from "./simulation/simulation-slice";
import warehouseSlice from "./warehouse/warehouse-slice";
import skuSlice from "./sku/sku-slice";
import datatableSlice from "./datatable/datatable-slice";
import forecastSlice from "./forecast/forecast-slice";
import reportSlice from "./report/report-slice";
import categoriesSlice from "./categories/categories-slice";
import configSlice from "./config/config-slice";
import major_categoriesSlice from "./major_categories/major_categories-slice";
import aggregationSlice from "./aggregation/aggregation-slice";
import sync_statusSlice from "./sync_status/sync_status-slice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    form: formSlice,
    datatable: datatableSlice,
    ui: uiSlice,
    vendor: vendorSlice,
    warehouse: warehouseSlice,
    simulation: simulationSlice,
    forecast: forecastSlice,
    sku: skuSlice,
    report: reportSlice,
    categories: categoriesSlice,
    config: configSlice,
    major_categories: major_categoriesSlice,
    aggregation: aggregationSlice,
    sync_status: sync_statusSlice,
  },
});

export default store;
