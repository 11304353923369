import React from 'react';
import { Descriptions, Divider, Spin } from 'antd';

function BasicDetails({
  title,
  isFetching = false,
  column,
  values = {},
  items = [],
}) {
  return isFetching ? (
    <div style={{ textAlign: 'center', height: '100%' }}>
      <Spin tip="Loading..." />
    </div>
  ) : (
    <>
      {title && <Divider orientation="left">{title}</Divider>}
      <Descriptions size="small" column={column} bordered>
        {items.map(({ label, dataIndex, span }) => (
          <Descriptions.Item label={label} key={dataIndex} span={span || 1}>
            {String(values[dataIndex])}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
}

export default BasicDetails;
