import { API_URI } from "../../config";
import { checkError, requestOption } from "../api/request";
import { reportAction } from "./report-slice";
import { generateCSV, generateExcel } from "../../utils/downloader";
import { format } from "date-fns";
import getVendorList from "../vendors/vendor-actions";

export const downloadReport = (payload, onSuccess) => {
  const { setIsLoading } = reportAction;

  return async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      const vendorList = await dispatch(getVendorList(payload.warehouse_code[0]));
      const { warehouse_code, vendor_code, file_type, category, source_type, uom } = payload;
      const file_name =
        `${warehouse_code}${
          vendor_code?.length > 0
            ? "_" + vendor_code?.map((v) => vendorList.find(({ code }) => +code === +v)?.name)?.join(",")
            : ""
        }${(category?.length > 0 && "_" + category.join(",")) || ""}${(source_type?.length > 0 &&
          "_" + source_type.join(",")) ||
          ""}_${format(new Date(), "ddMMyy_hhmmaaa")}`.replaceAll(".", "") + `(${uom})`;
      const data = await requestOption(`${API_URI}/admin/v1/download_report`, payload);
      file_type === "csv" ? generateCSV(data, file_name) : generateExcel(data, file_name);
      dispatch(setIsLoading());
      onSuccess();
    } catch (error) {
      dispatch(setIsLoading());
      checkError(error);
    }
  };
};
