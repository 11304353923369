/* eslint-disable */
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, Menu, Row } from "antd";
import { Divider } from "antd";
import growsariLogo from "../../../assets/Growsari-Logo-Main.png";
import userIcon from "../../../assets/user-icon.png";
import { LogoutOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { authAction, authState } from "../../../store/auth/auth-slice";
import localforage from "localforage";
import { ProgressBar } from "../../atoms/ProgressBar";

function SideNav({ sections }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { email } = useSelector(authState);
  const { setToken, isLoading } = authAction;
  const { pathname } = useLocation();

  const handleLogout = () => {
    dispatch(setToken(null));
    localStorage.clear();
    localforage.clear();
  };

  if (isLoading) {
    return <ProgressBar show={true} />;
  }

  useEffect(() => {
    if (!localStorage.role?.match(/lead|super_admin/i)) history.push("/home");
  }, []);

  return (
    <Row style={{ width: "100%", height: "100%" }} justify="space-between">
      <Col span={24}>
        <div style={{ backgroundColor: "#686868" }}>
          <div style={{ textAlign: "center", padding: "1.75rem 0" }}>
            <img src={growsariLogo} alt="growsari" width={120} />
          </div>
        </div>
        <Menu
          theme="dark"
          selectedKeys={pathname.match(/home|supply_plan/i) ? "/home" : pathname}
          defaultOpenKeys={
            pathname.match(/manage/i) ? "/configs" : pathname.match(/manage/i) ? "/home" : ""
          }
          mode="inline"
          style={{ fontWeight: 500, color: "white", backgroundColor: "#686868" }}
        >
          {sections.map(({ items, ...nav }) => {
            if (!items) {
              if (nav.submenu) {
                if (!localStorage.role?.match(/lead|super_admin/i)) return;

                return (
                  <Menu.SubMenu
                    key={nav.path}
                    icon={nav.icon}
                    title={nav.label}
                    disabled={Boolean(nav.disabled)}
                  >
                    {nav.submenu.map((subNav) => {
                      if (subNav.path === "/manage_accounts") {
                        return (
                          localStorage.role?.match(/lead|super_admin/i) && (
                            <Menu.Item
                              key={subNav.path}
                              disabled={Boolean(subNav.disabled)}
                              icon={subNav.icon}
                              onClick={() => history.push(subNav.path)}
                              style={{ paddingLeft: 30 }}
                            >
                              {subNav.label}
                            </Menu.Item>
                          )
                        );
                      }
                      return (
                        <Menu.Item
                          key={subNav.path}
                          disabled={Boolean(subNav.disabled)}
                          icon={subNav.icon}
                          onClick={() => history.push(subNav.path)}
                          style={{ paddingLeft: 30 }}
                        >
                          {subNav.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              }
            }
            return (
              <Menu.Item
                key={nav.path}
                disabled={Boolean(nav.disabled)}
                icon={nav.icon}
                style={{ color: "white" }}
                onClick={() => history.push(nav.path)}
              >
                {nav.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </Col>

      <Col span={24} style={{ padding: "0.5rem", height: "1rem" }}>
        <div style={{ padding: "0 1.5rem" }}>
          <Divider style={{ backgroundColor: "white" }} />
        </div>
        <div style={{ backgroundColor: "#686868", height: "100px" }}>
          <div
            style={{ textAlign: "center", paddingTop: "30px", paddingBottom: "30px", color: "white" }}
          >
            <div>
              <img src={userIcon} alt="growsari" width={20} />
            </div>
            <p style={{ fontWeight: 500, paddingTop: "0.25rem" }}> {email?.split("@")[0]}</p>
            <Button onClick={handleLogout} type="primary" style={{ width: "100%" }}>
              <b style={{ paddingRight: "0.5rem" }}>Logout</b>
              <LogoutOutlined style={{ transform: "rotate(90deg)" }} />
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default SideNav;
