import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forecastData: [],
  forecastColumn: [],
  initialForecastData: [],
  selectedSKU: [],
  forecastToShow: [],
  isLoading: false,
  UoM: "case",
  currentSku: {},
  PO_Forecast: [],
  revisionStatus: [],
  editableSupplyCell: [],
  rscUpdate: {},
};

const forecastSlice = createSlice({
  name: "forecast",
  initialState,
  reducers: {
    setForecastData(state, { payload }) {
      state.forecastData = payload;
    },
    setForecastColumn(state, { payload }) {
      state.forecastColumn = payload;
    },
    setInitialForecastData(state, { payload }) {
      state.initialForecastData = payload;
    },
    setSelectedSKU(state, { payload }) {
      state.selectedSKU = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setSkuRecord(state, { payload }) {
      state.skuRecord = payload;
    },
    setPO_Forecast(state, { payload }) {
      state.PO_Forecast = [...state.PO_Forecast, payload];
    },
    setCurrentSku(state, { payload }) {
      state.currentSku = payload;
    },
    setUoM(state, { payload }) {
      state.UoM = payload;
    },
    setForecastToShow(state, { payload }) {
      state.forecastToShow = payload;
    },
    setEditableSupplyCell(state, { payload }) {
      state.editableSupplyCell = payload;
    },
    setRevisionStatus(state, { payload }) {
      state.revisionStatus = { ...state.revisionStatus, ...payload };
    },
    setRSCupdate(state, { payload }) {
      state.rscUpdate = payload;
    },
  },
});

export default forecastSlice.reducer;
export const forecastAction = forecastSlice.actions;
export const forecastState = (state) => state.forecast;
