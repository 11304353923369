import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Tooltip } from "antd";
import GsSelect, { GsButton, GsInput } from "../../../atoms/customForm";
import { sortBy, snakeCase } from "lodash";
import VendorList from "./VendorList";
import { warehouseState } from "../../../../store/warehouse/warehouse-slice";
import { CloudUploadOutlined, DownloadOutlined } from "@ant-design/icons";
import ModalBox from "../../../atoms/ModalBox";
import { useModal } from "../../../../utils/useModal";
import VendorUploadModal from "./VendorUploadModal";
import getManageVendorList from "../../../../store/config/config-actions";
import { Spinner } from "../../../atoms/ProgressBar";
import { configState } from "../../../../store/config/config-slice";
import { downloadVendorList } from "../../../../store/vendors/vendor-actions";

const ManageVendor = () => {
  const dispatch = useDispatch();
  const { warehouseList } = useSelector(warehouseState);
  const { isLoading } = useSelector(configState);
  const gs_warehouse_id = localStorage.getItem("gs_warehouse_id");
  const userWareHouse = warehouseList.find((w) => w.id === gs_warehouse_id)?.code;

  const [formData, setFormData] = useState({ mfc: null, vendor_name_or_code: "QC" });
  const { modal, showModal } = useModal(false);

  const onChange = (value) => setFormData({ ...formData, ...value });

  const downloadVendor = () => dispatch(downloadVendorList(formData.mfc));

  useLayoutEffect(() => {
    userWareHouse && setFormData({ ...formData, mfc: userWareHouse });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWareHouse]);

  useEffect(() => {
    // userWareHouse && dispatch(getManageVendorList({ warehouse_code: formData.mfc }));
    formData.mfc && dispatch(getManageVendorList({ warehouse_code: formData.mfc }));
    setFormData({ ...formData, vendor_name_or_code: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.mfc]);

  return (
    <>
      <Spinner show={isLoading} height="95vh" />
      <Row
        align="bottom"
        // style={{ flexWrap: "nowrap" }}
      >
        <Col flex="200px" style={{ paddingRight: 24 }}>
          <GsSelect
            label="MFC"
            options={sortBy(warehouseList, ["value"])}
            onChange={onChange}
            value={formData.mfc}
          />
        </Col>

        <Col flex="210px">
          <GsInput
            label="Vendor name or code"
            aslabel="Search vendor name/code"
            onChange={(e) => onChange({ [snakeCase(e.target.name)]: e.target.value })}
            style={{ width: "100%", padding: "1rem" }}
            value={formData.vendor_name_or_code}
          />
        </Col>
        <Col flex="auto">
          <Row justify="end" style={{ paddingLeft: "1rem" }}>
            <Tooltip title="Download Vendors">
              <GsButton
                type="primary"
                label={<DownloadOutlined style={{ fontSize: 20, padding: 8 }} />}
                onClick={downloadVendor}
                style={{ padding: "0.25rem", margin: "0 12px" }}
                width="0"
                p="0"
                size="small"
              />
            </Tooltip>

            <Tooltip title="Upload/Update Vendor Info">
              <GsButton
                variant="text"
                label={<CloudUploadOutlined style={{ fontSize: 20, padding: 8 }} />}
                onClick={() => showModal(!modal)}
                style={{ padding: "0.25rem" }}
                width="0"
                p="0"
                size="small"
              />
            </Tooltip>
          </Row>
        </Col>
      </Row>
      <Row style={{ paddingTop: "2rem" }} align="bottom">
        {!formData.mfc ? (
          "Please select MFC."
        ) : (
          <VendorList filters={formData} searchVendor={formData?.vendor_name_or_code || ""} />
        )}
      </Row>
      <ModalBox
        showModal={modal}
        setShowModal={showModal}
        content={<VendorUploadModal onSuccess={showModal} />}
      />
    </>
  );
};

export default ManageVendor;
