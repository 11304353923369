import { API_URI } from "../../config";
import { checkError, requestOption } from "../api/request";
import { simulationAction } from "../simulation/simulation-slice";
import { skuAction } from "./sku-slice";
import { replace } from "lodash";
import { removeForage } from "../api/forage";
import { datatableAction } from "../datatable/datatable-slice";
import sendNotification from "../../utils/sendNotification";
const { setSimulationSkus, setActiveSimulation } = simulationAction;

export const fetchFinalizePlan = (simulation_id, onSuccess) => {
  const { setIsLoading } = skuAction;

  return async (dispatch) => {
    dispatch(setIsLoading(true));
    const payload = {
      id: simulation_id,
      status: "FINALIZED",
    };

    try {
      await requestOption(`${API_URI}/admin/v1/simulation`, payload, {
        type: "put",
        record: false,
      });
      removeForage("simulation?page=1&limit=100");
      setTimeout(() => onSuccess(), 300);
    } catch (error) {
      dispatch(setIsLoading(false));
      checkError(error);
    }
  };
};

export const fetchFinalizeSkus = (sku_ids, onSuccess) => {
  const { setIsLoading } = skuAction;

  return async (dispatch) => {
    dispatch(setIsLoading(true));
    const payload = {
      sku_id: sku_ids,
      status: "FINALIZED",
    };

    try {
      await requestOption(`${API_URI}/admin/v1/sku`, payload, { type: "put" });
      setTimeout(() => {
        onSuccess();
      }, 300);
    } catch (error) {
      dispatch(setIsLoading(false));
      checkError(error);
    }
  };
};

//create simulation
const getFilteredSku = (payload, page = 1, limit = 10, history) => {
  const { setIsLoading, setSkuPayload } = skuAction;

  const pagination = page ? `?page=${page}&limit=${limit}` : "";

  return async (dispatch) => {
    !payload.category.length && delete payload.category;
    !payload.vendor_code.length && delete payload.vendor_code;
    dispatch(setIsLoading(true));
    dispatch(setSkuPayload(payload));
    try {
      const { data } = await requestOption(`${API_URI}/admin/v1/simulation${pagination}`, payload, {
        record: false,
      });

      removeForage("simulation?page=1&limit=100");
      await dispatch(
        setActiveSimulation({
          ...data.data,
          vendor_code: payload.vendor_code,
          mfc: payload.warehouse_code.toString(),
        })
      );

      setTimeout(async () => {
        await dispatch(setIsLoading(false));
        history.push(`/supply_plan/${replace(data.data.simulation_name, "-", "").toLowerCase()}`);
      }, 300);
    } catch (error) {
      dispatch(setIsLoading(false));
      return sendNotification(
        "Failed request",
        error?.response?.data?.data || "Status 500, Server Error",
        "error"
      );
    }
  };
};

export const getSkuBySimulation = (simulation_id, page = 1, limit = 100) => {
  const { setIsLoading } = skuAction;
  const pagination = `&page=${page}&limit=${limit}`;

  return async (dispatch) => {
    if (!simulation_id) return;
    dispatch(setIsLoading(true));

    try {
      const { data } = await requestOption(
        `${API_URI}/admin/v1/sku?simulation_id=${simulation_id}${pagination}`
      );
      await dispatch(datatableAction.setTotalCount(data.total_count));

      removeForage(`${API_URI}/admin/v1/sku?simulation_id=${simulation_id}${pagination}`);

      // call searchabledList for feature use
      +data.total_count > 100 && getSkuBySimulation(simulation_id, 1, 100);

      dispatch(setSimulationSkus(data));
      setTimeout(() => {
        dispatch(setIsLoading(false));
      }, 300);
    } catch (error) {
      dispatch(setIsLoading(false));
      checkError(error);
    }
  };
};

export const getAllSimulationSKU = (simulation_id) => {
  const pagination = `&page=${1}&limit=${1000}`;
  return async (dispatch) => {
    // dispatch(setIsLoading(true));
    try {
      if (!simulation_id) return;
      const { data } = await requestOption(
        `${API_URI}/admin/v1/sku?simulation_id=${simulation_id}${pagination}`
      );
      dispatch(skuAction.setSearchableSkuList(data.data));
      // dispatch(setIsLoading(false));
    } catch (error) {
      // dispatch(setIsLoading(false));
      checkError(error);
    }
  };
};

export default getFilteredSku;
