import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { simulationState } from "../../../store/simulation/simulation-slice";
import EditableTable from "../../molecules/EditableTable/EditableTable";
import { columnDef } from "./../sku_screen/tableOption";
import { getSkuBySimulation } from "../../../store/sku/sku-actions";
import { datatableAction, datatableState } from "../../../store/datatable/datatable-slice";
import { flatMap } from "lodash";
import { skuAction, skuState } from "../../../store/sku/sku-slice";

const Instance = () => {
  const dispatch = useDispatch();
  const { recordSelectedSku } = skuAction;
  const { setSelectedItem } = datatableAction;
  const { selectedItem } = useSelector(datatableState);
  const { simulationSkus, simulationSkusTotal, simulationSkusPage } = useSelector(simulationState);
  const { searchSKU, searchableSkuList } = useSelector(skuState);
  const [filteredSKU, setFilteredSKU] = useState([]);

  const {
    activeSimulation: { simulation_id },
  } = useSelector(simulationState);

  const handlePageChange = (page, limit) => dispatch(getSkuBySimulation(simulation_id, page, limit));

  const onSelect = (ids, info) => {
    dispatch(setSelectedItem({ ...selectedItem, [simulationSkusPage]: ids }));
    dispatch(recordSelectedSku(info));
  };

  useEffect(() => {
    const searchResult = searchableSkuList.filter(({ name, sku_code }) => {
      const isString = isNaN(+searchSKU);
      if (isString) return name.toLowerCase().includes(searchSKU.toLowerCase());
      return sku_code.includes(searchSKU);
    });
    setFilteredSKU(searchResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSKU]);

  return (
    <>
      <EditableTable
        rowKey="id"
        rowClassName={() => "editable-row"}
        columns={columnDef}
        dataSource={searchSKU.length ? filteredSKU : simulationSkus}
        pagination={{
          showSizeChanger: true,
          total: searchSKU ? filteredSKU.length : simulationSkusTotal,
          pageSizeOptions: [5, 10, 20, 50, 100],
          defaultPageSize: 100,
          onChange: handlePageChange,
        }}
        size="100"
        scroll={{ x: "500px", y: "60vh" }}
        rowSelection={{
          onChange: onSelect,
          selectedRowKeys: flatMap(Object.values(selectedItem)),
        }}
      />
    </>
  );
};

export default Instance;
