import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { warehouseState } from "../../../store/warehouse/warehouse-slice";
import { Col, Row } from "antd";
import GsSelect, { GsButton, GsInput } from "../../atoms/customForm";
import { sortBy, snakeCase } from "lodash";
import { categoriesState } from "../../../store/categories/categories-slice";
import getCategories from "../../../store/categories/categories-actions";
import SkuList from "./SkuList";

const ManageSku = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ mfc: "", category: [] });
  const { warehouseList } = useSelector(warehouseState);
  const { categoryList } = useSelector(categoriesState);

  useEffect(() => {
    setFormData({ ...formData, category: [] });
    formData.mfc.length && dispatch(getCategories(formData.mfc));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.mfc]);

  const onChange = (value) => setFormData({ ...formData, ...value });
  const onSubmit = () => console.log(formData);

  return (
    <>
      <Row align="bottom">
        <Col flex="225px">
          <GsSelect label="MFC" options={sortBy(warehouseList, ["value"])} onChange={onChange} />
        </Col>
        <Col flex="225px">
          <GsSelect
            label="Category"
            options={sortBy(
              categoryList?.map((c) => ({ label: c, value: snakeCase(c) })),
              ["label"]
            )}
            onChange={onChange}
            multiple
          />
        </Col>
        <Col flex="175px">
          <GsInput
            label="Item code"
            aslabel="Search Item code"
            onChange={(e) => onChange({ [snakeCase(e.target.name)]: e.target.value })}
            style={{ marginTop: "0.25rem", width: "100%" }}
          />
        </Col>
        <Col flex="auto">
          <GsButton
            flex="100px"
            type="primary"
            label="Filter"
            style={{ padding: "0.75rem", marginLeft: "1.5rem" }}
            onClick={onSubmit}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: "2rem" }} align="bottom">
        <SkuList filters={formData} searchSKU={formData?.item_code || ""}/>
      </Row>
    </>
  );
};

export default ManageSku;
