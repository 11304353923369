import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  aggregationObj: {},
  aggregationDate: [],
  aggregationWH: null,
  aggregationInverval: "week",
  aggregationUom: "qty",
  aggregationFileName: null,
  aggregationFilters: [],
  aggregationResult: [],
  isLoading: false,
};

const aggregationSlice = createSlice({
  name: "aggregation",
  initialState,
  reducers: {
    setAggregationObj(state, { payload }) {
      state.aggregationObj = payload;
    },
    setAggregationResult(state, { payload }) {
      state.aggregationResult = payload;
    },
    setAggregationFilters(state, { payload }) {
      state.aggregationFilters = payload;
    },
    setAggregationDate(state, { payload }) {
      state.aggregationDate = payload;
    },
    setAggregationWH(state, { payload }) {
      state.aggregationWH = payload;
    },
    setAggregationInverval(state, { payload }) {
      state.aggregationInverval = payload;
    },
    setAggregationUom(state, { payload }) {
      state.aggregationUom = payload;
    },
    setAggregationFileName(state, { payload }) {
      state.aggregationFileName = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export default aggregationSlice.reducer;
export const aggregationAction = aggregationSlice.actions;
export const aggregationState = (state) => state.aggregation;
