import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manage_vendorList: [],
  manage_accountList: [],
  search_account: "",
  search_account_result: [],
  isLoading: false,
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setManage_vendorList(state, { payload }) {
      state.manage_vendorList = payload;
    },
    setManage_accountList(state, { payload }) {
      state.manage_accountList = payload;
    },
    setSearch_account(state, { payload }) {
      state.search_account = payload;
    },
    setSearch_account_result(state, { payload }) {
      state.search_account_result = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export default configSlice.reducer;
export const configAction = configSlice.actions;
export const configState = (state) => state.config;
