import localforage from "localforage";
import { removeForage } from "../api/forage";
import { uiAction } from "./ui-slice";
import { format } from "date-fns";

const newNotification = (message, severity = "success") => {
  const { setNotification } = uiAction;
  return async (dispatch) => {
    dispatch(
      setNotification({
        message: message,
        type: severity,
      })
    );
  };
};

export const clearSessionData = (resetAll, excludeKeys = ["login", "vendor", "warehouse"]) => {
  return async (dispatch) => {
    const sessionHour =
      format(new Date(localStorage.getItem("session")), "dd-MM-yyyy@HH") ===
      format(new Date(), "dd-MM-yyyy@HH");
    let allKeys = [];
    if (resetAll || !sessionHour || !sessionStorage.getItem("session")) {
      await localforage
        .keys()
        .then((keys) => {
          allKeys = keys;
        })
        .catch((error) => {
          console.error("Error retrieving keys:", error);
        });
      allKeys.map((key) => !excludeKeys.includes(key) && removeForage(key));
    }
  };
};

export default newNotification;
