import React, { useState } from "react";
import { useEffect } from "react";
import { getForage } from "../../../store/api/forage";
import { Button, Col, Row, Switch } from "antd";

const SkuList = ({ filters, searchSKU }) => {
  // const dispatch = useDispatch();
  const [filteredSKU, setFilteredSKU] = useState([]);
  const [skuCollection, setSkuCollection] = useState([]);
  useEffect(() => {
    // console.log(filters);
    // dispatch(getFilteredSku(filters));  //NEED API FOR THE FILTER && UPDATE
    getForage("sku?simulation_id=a24dba8d40954c1586eeda300ef5577d&page=1&limit=10").then((x) =>
      setSkuCollection(x.data.data)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchResult = skuCollection.filter(({ sku_code }) => sku_code.includes(searchSKU));
    setFilteredSKU(searchResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSKU]);

  // sku_code, sku_name, minimum_qty, max_qty, for planning
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  return (
    <>
      <Row className="tx550 tx100" style={{ width: "-webkit-fill-available" }}>
        <Col flex="10vw">Item Code</Col>
        <Col flex="auto">SKU Name</Col>
        <Col flex="10vw">Minimum QTY</Col>
        <Col flex="10vw">Maximum QTY</Col>
        <Col flex="10vw">For planning</Col>
        <Col flex="100px">Action</Col>
      </Row>
      {(filteredSKU?.length ? filteredSKU : skuCollection).map((sku) => (
        <Row
          className="tx400"
          style={{ fontSize: "small", padding: "0.25rem 0", width: "-webkit-fill-available" }}
        >
          {["sku_code", "name", "min_qty_in_mfc", "max_qty_in_mfc", "is_active", "action"].map((x) => (
            <Col
              flex={x === "name" ? "auto" : x === "action" ? "100px" : "10vw"}
              style={{
                width: "100px",
              }}
            >
              {x === "is_active" ? (
                <Switch
                  checkedChildren="ON "
                  unCheckedChildren="OFF"
                  size="small"
                  defaultChecked={sku[x] === 1}
                  onChange={onChange}
                />
              ) : ["min_qty_in_mfc", "max_qty_in_mfc"].includes(x) ? (
                <Row
                  style={{
                    justifyContent: "center",
                    width: "70%",
                    borderBottom: ["min_qty_in_mfc", "max_qty_in_mfc"].includes(x) && "1px solid #000",
                  }}
                >
                  <input type="text" defaultValue={sku[x]} className="reset" />
                </Row>
              ) : ["action"].includes(x) ? (
                <Row
                  style={{
                    justifyContent: "center",
                    width: "70%",
                    borderBottom: ["min_qty_in_mfc", "max_qty_in_mfc"].includes(x) && "1px solid #000",
                  }}
                >
                  <Button className="c1 tx75" size="small">
                    Submit
                  </Button>
                </Row>
              ) : (
                <Row
                  style={{
                    justifyContent: "center",
                    width: "70%",
                  }}
                >
                  {sku[x]}
                </Row>
              )}
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export default SkuList;
