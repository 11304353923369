import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  skuList: [],
  skuPagination: 1,
  skuTotal: null,
  selectedSku: [],
  selectedSkuInfo: [],
  isLoading: false,
  skuPayload: {},
  searchSKU: "",
  searchableSkuList: []
};

const skuSlice = createSlice({
  name: "sku",
  initialState,
  reducers: {
    setSkuList(state, { payload }) {
      state.skuList = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setSkuTotal(state, { payload }) {
      state.skuTotal = payload;
    },
    setSelectedSku(state, { payload }) {
      state.selectedSku = payload;
    },
    setSkuPagination(state, { payload }) {
      state.skuPagination = payload;
    },
    setSkuPayload(state, { payload }) {
      state.skuPayload = payload;
    },
    recordSelectedSku(state, { payload }) {
      // console.log(payload);
      state.selectedSkuInfo = [...state.selectedSkuInfo, ...payload];
    },
    setSearchSku(state, { payload }) {
      state.searchSKU = payload;
    },
    setSearchableSkuList(state, { payload }) {
      state.searchableSkuList = payload;
    },
  },
});

export default skuSlice.reducer;
export const skuAction = skuSlice.actions;
export const skuState = (state) => state.sku;
