import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryList: [],
  isLoading: true,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategoryList(state, {payload}) {
      state.categoryList = payload;
    },
  },
});

export default categoriesSlice.reducer;
export const categoriesAction = categoriesSlice.actions;
export const categoriesState = (state) => state.categories;