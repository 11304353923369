import { Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import GsSelect, { GsButton, GsRangePicker } from "../../atoms/customForm";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { snakeCase, sortBy, upperCase } from "lodash";
import { addMonths, format, addDays } from "date-fns";
import AggregationFilters from "./Filters";
import { warehouseState } from "../../../store/warehouse/warehouse-slice";
import { useModal } from "../../../utils/useModal";
import ModalBox from "../../atoms/ModalBox";
import { aggregationAction, aggregationState } from "../../../store/aggregation/aggregation-slice";
import getAggregationData, { downloadAggregation } from "../../../store/aggregation/aggregation-actions";
import { ProgressBar } from "../../atoms/ProgressBar";

const Aggregations = () => {
  const dispatch = useDispatch();
  const { modal, showModal } = useModal(true);
  const { warehouseList } = useSelector(warehouseState);
  const { isLoading, aggregationUom, aggregationResult, aggregationFilters } = useSelector(
    aggregationState
  );

  const [formData, setFormData] = useState({
    // warehouse: null,
    warehouse: "QC",
    date: [
      format(addDays(addMonths(new Date(), -1), -1), "yyyy-MM-dd"),
      format(addMonths(new Date(), 1), "yyyy-MM-dd"),
    ],
    time_intervals: "week",
  });

  const filename = `${formData.warehouse}(${formData.date.join("to")}), uom:${upperCase(
    aggregationUom
  )}, by:${upperCase(formData.time_intervals)}${
    aggregationFilters.length ? ", " + aggregationFilters.join(", ") : ""
  }`;

  const downloadAggregationTable = () => dispatch(downloadAggregation(aggregationResult, filename));

  useEffect(() => {
    dispatch(aggregationAction.setAggregationDate([formData.date[0], formData.date[1]]));
    dispatch(aggregationAction.setAggregationWH(formData.warehouse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.date, formData.warehouse]);

  useEffect(() => {
    dispatch(aggregationAction.setAggregationInverval(formData.time_intervals));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.time_intervals]);

  const onChange = (value) => {
    if (value.hasOwnProperty("uom")) {
      dispatch(aggregationAction.setAggregationUom(value.uom));
    } else {
      setFormData({ ...formData, ...value });
    }
  };

  return (
    <>
      <ProgressBar
        show={isLoading}
        offset="220px"
        style={{
          background: "rgba(0, 0, 0, 0.025)",
          left: 0,
          width: "100vw",
        }}
      />
      <ModalBox
        showModal={modal}
        // closable={false}
        setShowModal={() => {
          // formData.warehouse
          // sendNotification("Failed request", "Server Error", "error");
          showModal(false);
        }}
        content={
          <Col style={{ padding: "1rem 0" }}>
            <Col style={{ padding: "0.25rem" }}>
              <GsSelect
                label="Warehouse"
                options={sortBy(warehouseList, ["value"])}
                onChange={onChange}
                width="100%"
                defaultValue={formData.warehouse}
              />
            </Col>
            <Col direction="vertical" size={12} style={{ padding: "1.25rem 0 1.5rem 0" }}>
              <GsRangePicker
                label="Date Range"
                defaultValue={[moment(formData.date[0]), moment(moment(formData.date[1]))]}
                onChange={(_date, dateString) => onChange({ date: dateString })}
              />
            </Col>
            <Row justify="center">
              <GsButton
                label="Confirm"
                onClick={(_) => {
                  showModal(false);
                  dispatch(
                    getAggregationData({
                      start_date: formData.date[0],
                      end_date: formData.date[1],
                      warehouse_code: formData.warehouse,
                    })
                  );
                }}
                type="primary"
                style={{ padding: "0.75rem", width: "240px" }}
              />
            </Row>
          </Col>
        }
      />
      <Row
        style={{
          marginBottom: "0.25rem",
          // paddingBottom: "0.75rem",
          alignItems: "center",
          justifyContent: "end",
        }}
        gutter={8}
      >
        <Col flex="150px" className="c3 tx400 tx90">
          Warehouse :
          <span className="c3 tx600 tx90" style={{ margin: "0 0.5rem" }}>
            {formData.warehouse}
          </span>
        </Col>
        <Col className="c3 tx400 tx90">
          Date :
          <span className="c3 tx600 tx90" style={{ margin: "0 0.5rem" }}>
            {`${format(new Date(formData.date[0]), "MMM dd,yyyy")} - ${format(
              new Date(formData.date[1]),
              "MMM dd,yyyy"
            )}`}
          </span>
        </Col>
        <Row justify="center" style={{ paddingLeft: "0.5rem" }}>
          <GsButton
            label={aggregationResult.length ? "Edit" : "Create Aggregation"}
            onClick={() => showModal(!modal)}
            type="secondary"
            style={{ padding: "0rem", width: "100%" }}
          />
        </Row>
      </Row>

      <Row align="top" gutter={6}>
        <Col flex="140px">
          <GsSelect
            label="time_intervals"
            options={["Day", "Week", "Month"].map((x) => ({ value: snakeCase(x), label: x }))}
            onChange={onChange}
            defaultValue={formData.time_intervals}
            width="130px"
          />
        </Col>
        <Col flex="140px">
          <GsSelect
            label="UOM"
            options={[
              { value: "qty", label: "Qty" },
              { value: "cbm", label: "CBM" },
              { value: "case", label: "Case" },
              { value: "peso", label: "Peso" },
            ]}
            onChange={onChange}
            value={aggregationUom}
            width="130px"
          />
        </Col>
        <Col style={{ padding: "1.875rem 0 0 0.25rem" }}>
          <GsButton
            label="Download"
            onClick={downloadAggregationTable}
            type="primary"
            style={{ padding: "0.8rem", width: "140px" }}
            // offset="1.25rem"
          />
        </Col>
      </Row>

      <Divider gutter={2} style={{ margin: "1.25rem 0" }} />

      
      <AggregationFilters warehouse={formData.warehouse} uom={aggregationUom} />
    </>
  );
};

export default Aggregations;
