import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sync_statusList: [],
  isLoading: false,
};

const sync_statusSlice = createSlice({
  name: "sync_status",
  initialState,
  reducers: {
    setSync_statusList(state, { payload }) {
      state.sync_statusList = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export default sync_statusSlice.reducer;
export const sync_statusAction = sync_statusSlice.actions;
export const sync_statusState = (state) => state.sync_status;
