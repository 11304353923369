import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ModalBox from "../atoms/ModalBox";
import DownloadSimulation from "../molecules/Modals/DownloadSimulation";
import { GsButton } from "../atoms/customForm";
import { useModal } from "../../utils/useModal";
import { Col, Row, Tooltip } from "antd";
import SimulationList from "../organisms/simulation/SimulationList";
import { Spinner } from "../atoms/ProgressBar";
import { simulationState } from "../../store/simulation/simulation-slice";
import { useDispatch, useSelector } from "react-redux";
import getSimulationData from "../../store/simulation/simulation-actions";
import { datatableAction, datatableState } from "../../store/datatable/datatable-slice";
import { skuAction } from "../../store/sku/sku-slice";

const SimulationPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { modal, showModal } = useModal(false);
  const { isLoading } = useSelector(simulationState);
  const { currentPage } = useSelector(datatableState);

  const addSimulation = () => history.push("/supply_plan");
  const onRefetchSimulation = () => dispatch(getSimulationData(currentPage, 100, { record: false }));

  useEffect(() => {
    dispatch(datatableAction.setSelectedItem({}));
    dispatch(skuAction.setSearchableSkuList([]));
    dispatch(skuAction.setSearchSku(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner show={isLoading} />
      <ModalBox
        showModal={modal}
        setShowModal={showModal}
        content={<DownloadSimulation onSuccess={showModal} />}
      />
      <Row justify="space-between" align="center" style={{ paddingBottom: "1rem" }}>
        <Col
          className="tx700 pointer"
          style={{ paddingTop: "0.25rem" }}
          onDoubleClick={onRefetchSimulation}
        >
          <Tooltip title="Double click to refresh" placement="right">
            Today
          </Tooltip>
        </Col>
        <Col flex="auto">
          <Row justify="end">
            {/* HIDE since no function yet */}
            {/* <Col flex="100px">
              <GsButton
                flex="100px"
                label="Generate Plan"
                type="primary"
                style={{ padding: "0.65rem", marginRight: "1rem" }}
                onClick={() => console.log("test")}
              />
            </Col> */}
            <Col flex="100px">
              <GsButton
                label="Download Report"
                style={{ padding: "0.65rem" }}
                onClick={() => showModal(!modal)}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: "2%" }}>
        <SimulationList />
        <Row
          justify="end"
          span={24}
          style={{ margin: "0.75rem 0", width: "100%", paddingRight: "0.65rem" }}
        >
          <GsButton
            label="+ Add Simulation"
            onClick={addSimulation}
            type="secondary"
            style={{ padding: ".25rem 0.5rem", width: "100%" }}
          />
        </Row>
      </Row>
    </>
  );
};

export default SimulationPage;
