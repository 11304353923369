import { saveAs } from "file-saver";
import jsonexport from "jsonexport";
import * as XLSX from "xlsx";

export const generateExcel = async (content, filename) => {
  const xlsxContent = await content.split("\n").map((line) => line.split(","));
  const worksheet = XLSX.utils.aoa_to_sheet(xlsxContent);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export const generateCSV = (content, filename) => {
  const blob = new Blob([content], { type: "text/csv" });
  saveAs(blob, `${filename}.csv`);
};

export const generateJsonToCSV = async (data, filename) => {
  await jsonexport(data, function(err, csv) {
    if (err) return console.error(err);
    generateCSV(csv, filename);
  });
};
