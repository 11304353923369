import React from "react";
import { SyncOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSyncStatus } from "../../store/sync_status/sync_status-actions";
import { sync_statusState } from "../../store/sync_status/sync_status-slice";
import { Spinner } from "../atoms/ProgressBar";
import SyncStatusList from "../organisms/sync_status/SyncStatusList";
import { warehouseState } from "../../store/warehouse/warehouse-slice";

const SyncStatusPage = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(sync_statusState);
  const { warehouseList } = useSelector(warehouseState);

  const onRefresh = () => {
    dispatch(getSyncStatus(warehouseList));
  };

  return (
    <>
      <Spinner show={isLoading} />

      <Row justify="space-between" align="center" style={{ paddingBottom: "1rem" }}>
        <Col className="tx700 pointer" style={{ paddingTop: "0.25rem" }}>
          SPT Sync Status
        </Col>
        <Col className="tx700 pointer" style={{ paddingTop: "0.25rem" }}>
          <Tooltip title="Refresh">
            <Button
              shape="circle"
              icon={<SyncOutlined style={{ color: "#07A197" }} />}
              size="large"
              onClick={onRefresh}
            />
          </Tooltip>
        </Col>
      </Row>
      <SyncStatusList />
    </>
  );
};

export default SyncStatusPage;
