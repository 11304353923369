import { checkError, requestOption } from "../api/request";
import { authAction } from "./auth-slice";
import { formAction } from "../form/form-slice";
import { setSession, getForage, setForage } from "../api/forage";
import { API_URI } from "../../config";
import getWarehouseList from "../warehouse/warehouse-actions";
import newNotification from "../ui/ui-actions";
import { format } from "date-fns";

const loginAuth = (payload) => {
  const { setError } = formAction;
  const { setUser, setToken, setEmail, setIsLoading } = authAction;
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await requestOption(`${API_URI}/auth/v1/login`, payload);
      dispatch(setUser(data));
      dispatch(setToken(data.jwt_token));
      dispatch(setEmail(data.email));

      // save session
      localStorage.setItem("token", data.jwt_token);
      localStorage.setItem("email", data.email);
      localStorage.setItem("role", data.role);
      localStorage.setItem("gs_warehouse_id", data.gs_warehouse_id);
      setSession("session", new Date());
      dispatch(newNotification("Login successfully!"));
      dispatch(setIsLoading(false));
    } catch (error) {
      localStorage.clear();
      dispatch(setIsLoading(false));
      dispatch(setError("Incorrect credential"));
      dispatch(newNotification("Incorrect credential", "error"));
      checkError(error);
    }
  };
};

export const isAuthenticated = (history, token) => {
  const { setUser } = authAction;
  return async (dispatch) => {
    const session = format(new Date(), "dd-MM-yyyy") === localStorage.getItem("session");
    const currentUser = await getForage("login");
    // const simulation = await getForage("simulation?page=1&limit=100");

    try {
      if (token && !session) {
        await setForage("login", currentUser);
        // await setForage("simulation?page=1&limit=100", simulation);
        dispatch(setUser(currentUser));
        // localStorage.setItem("session", format(new Date(), "dd-MM-yyyy,HH:mm"));
        localStorage.setItem("session", new Date());
      }
      if (!token) return history.push("/login");
      dispatch(getWarehouseList());
      sessionStorage.setItem("session", "session");
    } catch (error) {
      checkError();
      console.log("error: checking isAuthenticated()");
    }
  };
};

export default loginAuth;
