import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportObj: {},
  isLoading: false,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setreportObj(state, { payload }) {
      state.reportObj = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export default reportSlice.reducer;
export const reportAction = reportSlice.actions;
export const reportState = (state) => state.report;
