import React from "react";
import { useDispatch } from "react-redux";
import { forecastAction, forecastState } from "../../store/forecast/forecast-slice";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { uiState } from "../../store/ui/ui-slice";
import { simulationState } from "../../store/simulation/simulation-slice";
import { startCase, pickBy, keys, join } from "lodash";
import { fetchFinalizeSkus } from "../../store/sku/sku-actions";
import { DownloadIcon } from "../../assets/icons";
import ModalBox from "../atoms/ModalBox";
import { useModal } from "../../utils/useModal";
import DownloadSimulation from "../molecules/Modals/DownloadSimulation";
import { Row, Tooltip, Col } from "antd";
import GsSelect, { GsButton } from "../atoms/customForm";
import ForeCastList from "../organisms/supply_plan/ForeCastList";
import sendNotification from "../../utils/sendNotification";
import { ProgressBar } from "../atoms/ProgressBar";
import { datatableAction } from "../../store/datatable/datatable-slice";

const ForecastPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setUoM } = forecastAction;
  const { modal, showModal } = useModal(false);
  const { setSelectedItem } = datatableAction;
  const { UoM, forecastData, revisionStatus, isLoading, rscUpdate } = useSelector(forecastState);
  const {
    activeSimulation: { simulation_name },
  } = useSelector(simulationState);
  const { previousPage } = useSelector(uiState);

  const handleChange = (e) => dispatch(setUoM(e.uom));
  const onSuccess = () => {
    dispatch(setSelectedItem([]));
    history.push(previousPage);
  };

  const onFinalizeSku = () => {
    const activeRevision = join(keys(pickBy(revisionStatus, (value) => !!value)));

    if (activeRevision) {
      return sendNotification(
        `Please check SKU${activeRevision.match(/,/i) ? "'s" : ""} ${activeRevision}`,
        "Recalculate updated SKU's to Finalizing",
        "error"
      );
    }

    const sku_ids = forecastData.map((x) => x[0].sku_id);

    if (sku_ids.length > 0) {
      dispatch(fetchFinalizeSkus(sku_ids, onSuccess));
    } else console.log("No SKU's to finalized");
  };

  !simulation_name && history.push("/home");

  return (
    <>
      <Row justify="space-between" align="center">
        <Col className="tx700" style={{ paddingTop: "0.25rem" }}>
          <span
            className="tx700 pointer"
            onClick={() => {
              dispatch(setSelectedItem([]));
              history.push(previousPage);
            }}
          >
            <Tooltip title="Go back" placement="right">
              {startCase(simulation_name)}
            </Tooltip>
          </span>
        </Col>
        <Col flex="auto">
          <Row justify="end">
            <Col flex="50px">
              <GsButton
                variant="text"
                label={
                  <Row>
                    <DownloadIcon />
                  </Row>
                }
                onClick={() => showModal(!modal)}
                style={{ padding: "0.25rem" }}
                width="0"
                p="0"
                size="small"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="space-around" align="top" style={{ paddingTop: "1rem" }}>
        <Col flex="200px">
          <GsSelect
            aslabel="UoM"
            value={UoM}
            options={[
              { value: "qty", label: "Qty." },
              { value: "case", label: "Case" },
              { value: "cbm", label: "CBM" },
              { value: "peso", label: "Peso" },
            ]}
            onChange={handleChange}
            width="180px"
          />
        </Col>
        <Col flex="auto">
          <Col>
            Stock transfers last update is
            <b className="c3 tx85 tx550" style={{ paddingLeft: "0.25rem" }}>
              {rscUpdate.stock_transfer}
            </b>
          </Col>
          <Col>
            Stock balance last update is{" "}
            <b className="c3 tx85 tx550" style={{ paddingLeft: "0.25rem" }}>
              {rscUpdate.stock_balance}
            </b>
          </Col>
          <Col>
            GROWSARI_RECEIVED_PO_REPORT last update is
            <b className="c3 tx85 tx550" style={{ paddingLeft: "0.25rem" }}>
              {rscUpdate.growsari_received_po_report}
            </b>
          </Col>
          <Col>
            GROWSARI_RELEASE_PO_REPORT_VALID_PO last update is
            <b className="c3 tx85 tx550" style={{ paddingLeft: "0.25rem" }}>
              {rscUpdate.growsari_release_po_report_valid_po}{" "}
            </b>
          </Col>
        </Col>
        <Col flex="100px">
          <GsButton
            label="Finalize SKU's"
            style={{ padding: "0.65rem" }}
            type="primary"
            onClick={onFinalizeSku}
          />
        </Col>
      </Row>

      <ForeCastList />
      <ProgressBar show={isLoading} />
      <ModalBox
        showModal={modal}
        setShowModal={showModal}
        content={<DownloadSimulation onSuccess={showModal} />}
      />
    </>
  );
};

export default ForecastPage;
