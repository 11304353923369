import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Select } from "antd";
import { configAction, configState } from "../../../../store/config/config-slice";
import { upperCase, sortBy } from "lodash";
import { updateAccount } from "../../../../store/config/config-actions";
import { warehouseState } from "../../../../store/warehouse/warehouse-slice";

const AccountList = () => {
  const dispatch = useDispatch();
  const { manage_accountList, search_account_result, search_account } = useSelector(configState);
  const { warehouseList } = useSelector(warehouseState);

  const onSubmit = (acc) => dispatch(updateAccount(acc));

  const onChangeRole = (newVal, obj, name = "user_type") => {
    const newObj = { ...obj, [name]: newVal };
    const updatedAccountList = manage_accountList.map((x) => (obj.id === x.id ? newObj : x));
    dispatch(configAction.setManage_accountList(updatedAccountList));
  };

  const cellsStyle = { minWidth: "200px" };

  return (
    <Row style={{ overflowX: "auto", maxWidth: "inherit" }}>
      <Row className="tx550 tx80" style={{ width: "-webkit-fill-available", flexWrap: "nowrap" }}>
        <Col flex="1.5" style={cellsStyle}>Account</Col>
        <Col flex="1" style={cellsStyle}>Role</Col>
        <Col flex="1.5" style={cellsStyle}>Warehouse</Col>
        <Col flex="1" style={cellsStyle}>Action</Col>
      </Row>
      {(search_account?.length ? search_account_result : manage_accountList).map((acc, i) => (
        <Row
          className="tx400"
          style={{
            fontSize: "small",
            padding: "0.25rem 0",
            width: "-webkit-fill-available",
            alignItems: "baseline",
            flexWrap: "nowrap"
          }}
          key={i}
        >
          {["gs_account", "user_type", "warehouse_code", "action"].map((x, key) => (
            <Col flex={x.match(/gs_account|warehouse_code/i) ? "1.5" : "1"} key={key}
            style={cellsStyle}
            >
              {x.match(/user_type/i) ? (
                <Row
                  style={{
                    justifyContent: "center",
                    width: "70%",
                  }}
                >
                  <Select
                    options={["lead", "planner", "super_admin"].map((x) => ({
                      value: upperCase(x),
                      label: upperCase(x),
                    }))}
                    name="user_type"
                    mode={x === "lead" && "multiple"}
                    style={{ width: "100%" }}
                    className="tx75"
                    defaultValue={upperCase(acc[x])}
                    // onChange={(val) => onChangeRole(val, acc)}
                    // disabled={localStorage.getItem("role")?.match(/lead/i)}
                    disabled={true}
                  />
                </Row>
              ) : x.match(/warehouse_code/i) ? (
                <Row
                  style={{
                    justifyContent: "center",
                    width: "70%",
                    minWidth: "150px",
                  }}
                >
                  <Select
                    options={sortBy([{ value: "*", label: "All warehouse" }, ...warehouseList])}
                    name="warehouse_code"
                    style={{ width: "100%" }}
                    className="tx75"
                    value={acc[x]}
                    onChange={(val) => {
                      // console.log(val);
                      // const allWarehouse = warehouseList.map((x) => x.value);
                      const removedMarkAll = val.filter((x) => x !== "*");
                      const newVal = val.includes("*") ? ["*"] : val.length > 1 ? removedMarkAll : val;
                      onChangeRole(newVal, acc, "warehouse_code");
                    }}
                    mode="multiple"
                    showSearch
                    showArrow
                  />
                </Row>
              ) : ["action"].includes(x) ? (
                <Row style={{ width: "70%" }}>
                  <Button className="c1 tx75" onClick={() => onSubmit(acc)}>
                    Submit
                  </Button>
                </Row>
              ) : (
                <Row style={{ width: "70%" }}>{acc[x]}</Row>
              )}
            </Col>
          ))}
        </Row>
      ))}
    </Row>
  );
};

export default AccountList;
