import { API_URI } from "../../config";
import { checkError, requestOption } from "../api/request";
import { simulationAction } from "./simulation-slice";
import { map, replace } from "lodash";
import getVendorList from "../vendors/vendor-actions";
import { datatableAction } from "../datatable/datatable-slice";
import { clearSessionData } from "../ui/ui-actions";
import sendNotification from "../../utils/sendNotification";
import { authAction } from "../auth/auth-slice";
import localforage from "localforage";

export const getSimulationData = (page = 1, limit = 5) => {
  const { setSimulationRecord, setIsLoading } = simulationAction;
  const { setTotalCount } = datatableAction;
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    // await dispatch(getVendorList());
    const pagination = `?page=${page}&limit=${limit}`;
    try {
      const { data } = await requestOption(`${API_URI}/admin/v1/simulation${pagination}`, null, {
        record: false,
      });
      const vendors = await dispatch(getVendorList());

      const getVendorName = (code) => {
        return code?.split(",")?.map((c) => vendors.find(({ code }) => code === c)?.name || "");
      };

      const result = await map(data?.data, (d) => ({
        ...d,
        ...JSON.parse(d.requested_filter),
        name: replace(d.name, "-", "").toLowerCase(),
        key: d.id,
        planner: d.planner_name,
        vendor_name: getVendorName(d.vendor_code),
        warehouse_name: d.warehouse_code,
      }));

      dispatch(setTotalCount(data.total_count));
      dispatch(setSimulationRecord(result));
      setTimeout(() => {
        dispatch(setIsLoading(false));
      }, 300);
      await dispatch(clearSessionData("resetAll"));
    } catch (error) {
      const sessionExpird = JSON.stringify(error).match(/status code 403/i);
      if (sessionExpird) {
        sendNotification("Your Sesssion has expired", "Please try to re-login again.", "error");
        dispatch(authAction.setToken(null));
        localStorage.clear();
        localforage.clear();
        dispatch(setIsLoading(false));
        return;
      }
      checkError(error);
    }
  };
};

export const updateSimulation = (payload) => {
  const { setInitialSimulationRecord, setIsLoading } = simulationAction;
  return async (dispatch) => {
    try {
      const { data } = await requestOption(`${API_URI}/admin/v1/sku`);
      const result = await map(data.data, (d) => ({
        ...d,
        ...JSON.parse(d.requested_filter),
        name: replace(d.name, "-", "").toLowerCase(),
        key: d.id,
      }));
      dispatch(setInitialSimulationRecord(result));
      dispatch(setIsLoading());
    } catch (error) {
      dispatch(setIsLoading());
      checkError(error);
    }
  };
};

export default getSimulationData;
