import { API_URI } from "../../config";
import { checkError, requestOption } from "../api/request";
import { sync_statusAction } from "./sync_status-slice";

export const getSyncStatus = (warehouse) => {
  const { setSync_statusList, setIsLoading } = sync_statusAction;
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const warehouse_codes = warehouse.map((w) => w.code);

      const { data } = await requestOption(
        `${API_URI}/admin/v1//warehouse_last_update?warehouse_code=${warehouse_codes}`
      );

      const result = data.map((x) => ({
        ...x,
        name: warehouse.find((w) => w.code === x.warehouse_code).name,
      }));
      dispatch(setSync_statusList(result));
      dispatch(setIsLoading());
    } catch (error) {
      dispatch(setIsLoading());
      console.log('error', error);
      checkError(error);
    }
  };
};
