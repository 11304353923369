import { newDate } from "../../../utils/helper";
import { differenceInDays } from "date-fns";

const columnProp = {
  width: "100%",
  maxWidth: 300,
  editable: false,
  resizable: true,
};

export const generateDates = (startDate = new Date(), length = 28) => {
  const dateStart = differenceInDays(new Date(startDate), new Date());
  const dateRow = [];
  for (let i = dateStart; i < dateStart + length; i++) {
    dateRow.push({
      field: `Week ${Math.floor((i - dateStart) / 7) + 1}`,
      width: 90,
      children: [
        {
          field: newDate(i, "EEE"),
          width: 85,
          children: [
            {
              field: newDate(i, "dd-MMM-yy"),
              width: 100,
              resizable: true,
            },
          ],
        },
      ],
    });
  }
  return dateRow;
};

export const columnHeader = [
  {
    field: "",
    children: [
      {
        children: [
          {
            field: "key_figure",
            headerName: "Key Figures",
            pinned: true,
            ...columnProp,
            width: 170,
          },
        ],
      },
    ],
  },
  {
    field: "week_no",
    headerName: "Week #",
    pinned: true,
    width: 200,
    children: [
      {
        field: "day",
        headerName: "Day",
        pinned: true,
        ...columnProp,
        children: [
          {
            field: "wtd_offtake",
            headerName: "Date",
            pinned: true,
            ...columnProp,
            width: 120,
          },
        ],
      },
    ],
  },
];
