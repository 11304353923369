import React, { useLayoutEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authState } from "../../store/auth/auth-slice";
import { isAuthenticated } from "../../store/auth/auth-actions";
// import AppDrawer from "../organism/AppBar";
import Admin from "../templates2/Admin/Admin";

function PrivateRoute({ component: RouteComponent, ...rest }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useSelector(authState);

  useLayoutEffect(() => {
    dispatch(isAuthenticated(history, token));
  }, [dispatch, history, token]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <Admin sidenav={rest.nav}>
          <Route {...rest} render={(props) => <RouteComponent {...props} />} />
        </Admin>
      )}
    />
  );
}

function GuestRoute({ component: RouteComponent, ...rest }) {
  const history = useHistory();
  const { token } = useSelector(authState);

  useLayoutEffect(() => {
    token && history.push("/home");
  }, [history, token]);

  return <Route {...rest} render={(props) => <RouteComponent {...props} />} />;
}

export { PrivateRoute, GuestRoute };
