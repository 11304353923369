import React from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { Button, Col, Row } from "antd";
import { GsButton } from "../../../atoms/customForm";
import { uploadNewVendor } from "../../../../store/config/config-actions";

export default function VendorUploadModal({onSuccess}) {
  const dispatch = useDispatch();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [".csv"],
    },
    multiple: false,
  });

  const onSubmit = () => {
    if (!acceptedFiles.length) return alert('Please select file to upload');
    dispatch(uploadNewVendor(acceptedFiles[0], onSuccess))
  };

  return (
    <div style={{ width: "80vw", maxWidth: "900px" }}>
      <Row style={{ padding: "1.5rem 1rem" }}>
        Upload CSV file and make sure to follow{" "}
        <a className="c1 pointer" style={{ paddingLeft: "0.25rem" }} href="https://spt-prod.s3.ap-southeast-1.amazonaws.com/vendor_template.csv" download> 
          sample format
        </a>
      </Row>
      <Col
        justify="center"
        align="middle"
        style={{
          borderRadius: "10px",
          background: "linear-gradient(#FFFFFF, #EFF0F6)",
          border: "1px dashed #6E7191",
          margin: "0rem 0.75rem",
          marginBottom: "0.5rem",
        }}
      >
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              textAlign: "center",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <input {...getInputProps()} />
            <b
              className="tx500"
              style={{
                fontSize: "0.9375rem",
                color: "#6E7191",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div> Drag and drop or upload any supporting photos</div>
              <div className="tx400" style={{ padding: "0.5rem" }}>
                or
              </div>
              <Button style={{ borderRadius: "8px", border: "1.75px solid #6E7191", py: 0.75, px: 3 }}>
                <b
                  className="tx500"
                  style={{ textTransform: "initial", fontSize: "0.85rem", color: "#6E7191" }}
                >
                  Select Files from your device
                </b>
              </Button>
            </b>

            {acceptedFiles.length > 0 && (
              <div className="tx400" style={{ fontSize: "0.85rem", color: "#6E7191", paddingTop: 12 }}>
                {acceptedFiles[0].name}
              </div>
            )}
          </div>
        </div>
      </Col>
      <Row justify="end" style={{ padding: "0.75rem" }}>
        <GsButton
          label="Upload"
          onClick={onSubmit}
          type="primary"
          style={{ padding: "0.75rem", width: "220px" }}
        />
      </Row>
    </div>
  );
}
