import { API_URI } from "../../config";
import sendNotification from "../../utils/sendNotification";
import { checkError, requestOption } from "../api/request";
import { vendorAction } from "./vendor-slice";
import { generateCSV } from "../../utils/downloader";
import { setForage } from "../api/forage";

const getVendorList = (warehouse_code) => {
  return async (dispatch) => {
    const { setVendorList } = vendorAction;
    const filters = warehouse_code ? `warehouse_code=${warehouse_code}&is_enabled=1` : "";

    try {
      const { data } = await requestOption(`${API_URI}/admin/v1/vendor?${filters}`, false, {
        refetch: false,
      });

      const serialize = data.map((x) => ({
        ...x,
        value: `${x.code || x.vendor_code}_${x.name}`,
        label: x.label || x.name,
      }));
      dispatch(setVendorList(serialize));
      setForage("vendor", serialize);
      return serialize;
    } catch (error) {
      checkError(error);
    }
  };
};

export const downloadVendorList = (warehouse) => {
  return async (dispatch) => {
    if (!warehouse)
      return sendNotification("No MFC selected", "Please selected MFC to download", "error");

    try {
      const data = await requestOption(
        `${API_URI}/admin/v1/vendor_download?warehouse_code=${warehouse}`
      );
      generateCSV(data, `${warehouse} vendors`);
    } catch (error) {
      checkError(error);
    }
  };
};

export default getVendorList;
