import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { GsInput } from "../../../atoms/customForm";
import AccountList from "./AccountList";
import { getManageAccountList } from "../../../../store/config/config-actions";
import { configAction, configState } from "../../../../store/config/config-slice";

const ManageAccounts = () => {
  const dispatch = useDispatch();
  const { manage_accountList } = useSelector(configState);

  useEffect(() => {
    dispatch(getManageAccountList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const query = e.target.value;
    const searchResult = manage_accountList.filter(({ gs_account }) =>
      gs_account.toLowerCase().includes(query.toLowerCase())
    );
    dispatch(configAction.setSearch_account(query));
    dispatch(configAction.setSearch_account_result(searchResult));
  };

  return (
    <>
      <Row align="bottom">
        <Col flex="175px">
          <GsInput
            label="Search Account"
            aslabel="Search Account"
            onChange={onChange}
            style={{ marginTop: "0.25rem", width: "100%" }}
          />
        </Col>
        {/* <Col flex="auto">
          <GsButton
            flex="100px"
            type="primary"
            label="Search"
            style={{ padding: "0.75rem", marginLeft: "1.5rem" }}
            onClick={onSubmit}
          />
        </Col> */}
      </Row>
      <Row style={{ paddingTop: "2rem" }} align="bottom">
        <AccountList />
      </Row>
    </>
  );
};

export default ManageAccounts;
