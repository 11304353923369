import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { vendorState } from "../../store/vendors/vendor-slice";
import { warehouseState } from "../../store/warehouse/warehouse-slice";
import getFilteredSku from "../../store/sku/sku-actions";
import { find, sortBy } from "lodash";
import GsSelect, { GsButton } from "../atoms/customForm";
import { Checkbox, Col, Row, Switch, Tooltip } from "antd";
import sendNotification from "../../utils/sendNotification";
import { useEffect } from "react";
import getCategories from "../../store/categories/categories-actions";
import { categoriesState } from "../../store/categories/categories-slice";
import getVendorList from "../../store/vendors/vendor-actions";
import { Spinner } from "../atoms/ProgressBar";
import { skuState } from "../../store/sku/sku-slice";

const FilterSku = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ mfc: null, vendor: [], category: [] });
  const [top100, setTop100] = useState(false);
  const { vendorList } = useSelector(vendorState);
  const { warehouseList } = useSelector(warehouseState);
  const { categoryList } = useSelector(categoriesState);
  const { isLoading } = useSelector(skuState);

  const onChange = (value) => {
    setFormData({ ...formData, ...value });
  };
  
  const onSubmit = () => {
    const payload = {
      warehouse_code: [warehouseList.find(({ code }) => code === formData.mfc)?.code],
      vendor_code: formData.vendor?.map(
        (v) => find(vendorList, (x) => x.id === +v.split("^")[0])?.vendor_code
      ),
      category: formData.category,
      is_top100: top100,
    };

    if (!formData.mfc) {
      return sendNotification("MFC is required", "Select MFC to plan", "error");
    }
    if (!formData.vendor.length && !formData.category.length && !top100) {
      return sendNotification(
        "Category, Vendor, and Top 100 selections are required.",
        "Select at least one option from Category, Vendor, or Top 100.",
        "error"
      );
    } else {
      dispatch(getFilteredSku(payload, null, null, history));
    }
  };

  useEffect(() => {
    dispatch(getVendorList(formData.mfc));
    formData.mfc && dispatch(getCategories(formData.mfc));
    setFormData({ ...formData, category: [], vendor: [] });
    // !vendorList.length &&
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.mfc]);

  return (
    <Row align="top">
      <Spinner show={isLoading} />
      <Col flex="160px">
        <GsSelect
          label="MFC"
          options={sortBy(warehouseList, ["value"])}
          onChange={onChange}
          width="140px"
        />
      </Col>

      <Col flex="300px">
        <GsSelect
          width="280px"
          label="Vendor"
          options={sortBy(
            vendorList.map(({ label, id }) => ({ label, value: `${id}^${label}` })),
            ["label"]
          )}
          onChange={onChange}
          multiple
          value={formData.vendor}
        />
      </Col>

      <Col flex="220px">
        <GsSelect
          label="category"
          options={categoryList.map((c) => ({ label: c, value: c }))}
          onChange={onChange}
          width="200px"
          multiple
        />
      </Col>

      <Col flex="150px">
        <Row>
          <h4 style={{ paddingRight: "0.5rem" }} className="c3 tx70">
            Top 100 SKU's
          </h4>
          <Checkbox checked={top100} style={{ cursor: "not-allowed" }} />
        </Row>
        <Row style={{ paddingTop: "0.75rem" }}>
          <Tooltip title="Filter Top 100 SKU's">
            <Switch
              size="large"
              checkedChildren={` Enable `}
              unCheckedChildren={` Disable `}
              checked={top100}
              onChange={(e) => setTop100(e)}
              style={{ height: "32px", padding: "0 0.5rem" }}
              className={!top100 ? "switch" : "switchon"}
            />
          </Tooltip>
        </Row>
      </Col>

      <Col flex="auto" style={{ margin: "1.8rem" }}>
        <GsButton
          flex="100px"
          type="primary"
          label="Display SKU's"
          style={{ padding: "1rem", marginLeft: "-1.75rem" }}
          onClick={onSubmit}
        />
      </Col>
    </Row>
  );
};

export default FilterSku;
