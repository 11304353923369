import { API_URI } from "../../config";
import { checkError, requestOption } from "../api/request";
import { aggregationAction } from "./aggregation-slice";
import { differenceInDays, addDays, format, getISOWeek, isSameMonth } from "date-fns";
import { startOfWeek, differenceInMonths, lastDayOfMonth, startOfMonth } from "date-fns";
import { addWeeks, addMonths, differenceInCalendarMonths, endOfMonth, getMonth } from "date-fns";
import { isNaN, startCase, mergeWith, update, sumBy, groupBy } from "lodash";
import { map, padStart, isArray } from "lodash";
import numeral from "numeral";
import sendNotification from "../../utils/sendNotification";
import { generateJsonToCSV } from "../../utils/downloader";

const getAggregationData = ({ start_date, end_date, warehouse_code }) => {
  const { setAggregationObj, setIsLoading } = aggregationAction;
  return async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      const queries = [];
      const months = differenceInCalendarMonths(new Date(end_date), new Date(start_date));
      const started_at_month = +format(new Date(start_date), "MM");
      const ended_at_month = +format(new Date(end_date), "MM");

      for (let i = 0; i < months + 1; i++) {
        if (i + started_at_month === started_at_month) {
          queries.push(
            `from=${format(
              startOfMonth(new Date(start_date)),
              `yyyy-${padStart((i + started_at_month).toString(), 2, "0")}-dd`
            )}&to=${format(endOfMonth(new Date(start_date)), `yyyy-MM-dd`)}`
          );
        } else if (i + started_at_month === ended_at_month) {
          queries.push(
            `from=${format(
              new Date(end_date),
              `yyyy-${padStart((i + started_at_month).toString(), 2, "0")}-01`
            )}&to=${format(new Date(end_date), `yyyy-MM-dd`)}`
          );
        } else {
          queries.push(
            `from=${format(
              new Date(start_date),
              `yyyy-${padStart((i + started_at_month).toString(), 2, "0")}-01`
            )}&to=${format(
              endOfMonth(addMonths(new Date(start_date), i)),
              `yyyy-${padStart((i + started_at_month).toString(), 2, "0")}-dd`
            )}`
          );
        }
      }

      let newObj = {};
      for (let i = 0; i < queries.length; i++) {
        const res = await requestOption(
          `${API_URI}/admin/v1/demand_info?&warehouse_code=${warehouse_code}&${queries[i]}`,
          false,
          {
            refetch: false,
          }
        );
        const mergedObj = mergeWith({}, newObj, res.data, (objValue, srcValue) => {
          if (isArray(objValue)) {
            return objValue.concat(srcValue);
          }
        });
        newObj = mergedObj;
      }
      dispatch(setAggregationObj(newObj));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      sendNotification("504 Gateway Timeout", "Network Error", "error");
    }
  };
};

export const generateAggregationTable = (
  data,
  aggregationDate,
  aggregationUom,
  aggregationInverval,
  isCbm
) => {
  const collectByDate = (runningDate) => {
    const runningDateArr = data.flatMap(
      (x) =>
        x.demand.find((z) => z.date === runningDate) && {
          ...x.demand.find((z) => z.date === runningDate),
          sku: x.sku,
        }
    );
    const updatedEndingDays = runningDateArr.map((cur) => ({
      ...cur,
      ending_days_cover: cur?.remaining / cur?.avg_14_day_demand,
    }));

    // const serializeData = aggregationInverval === "day" ? runningDateArr : updatedEndingDays;
    const serializeData = updatedEndingDays;

    const updatedUOM = serializeData.map((x) => {
      if (x !== undefined) {
        const newObject = {};
        Object.entries(x).map(([key, val]) => {
          const { case_config, cbm_per_case, gs_price_per_piece } = x?.sku || {};
          const UoM =
            aggregationUom === "case"
              ? 1 / case_config
              : aggregationUom === "cbm"
              ? cbm_per_case / case_config
              : aggregationUom === "peso"
              ? gs_price_per_piece
              : 1;

          if (isNaN(+val)) return (newObject[key] = val);
          if (key === "ending_days_cover") return (newObject[key] = val);
          // if (key === "lost_sales") return (newObject[key] = val * gs_price_per_piece);
          return (newObject[key] = val * UoM);
        });
        return newObject;
      }
      return {};
    });

    const runningDateSum = mergeWith({}, ...updatedUOM, (objValue, srcValue) => {
      if (!isNaN(+objValue) && !isNaN(+srcValue)) {
        return +objValue + +srcValue;
      }
    });

    return !runningDateSum.date ? { date: runningDate } : runningDateSum;
  };

  const result = {};
  const columnCount = differenceInDays(new Date(aggregationDate[1]), new Date(aggregationDate[0]));
  const dateRange = columnCount;

  for (let i = 0; i < dateRange + 1; i++) {
    const runningDate = format(addDays(new Date(aggregationDate[0]), i), "yyyy-MM-dd");
    result[runningDate] = collectByDate(runningDate);
  }

  const keys = Object.keys(result);

  const allDates = keys.map((x, i) => ({
    field: format(new Date(x), "dd-MMM-yy"),
    headerName: format(new Date(x), "dd-MMM-yy"),
    width: "150px",
    cellStyle: () => ({ textAlign: "right", paddingRight: "1rem" }),
    cellRenderer: ({ value }) =>
      isCbm ? numeral(value).format("0,0.00") : numeral(Math.ceil(value)).format("0,0"),
  }));
  const columnDef = [
    {
      field: "key_figure",
      headerName: "Key Figure",
      pinned: true,
      width: "175px",
    },
    ...allDates,
  ];

  const newObj = {};
  Object.entries(result).map(([key, val]) =>
    [
      "demand",
      "daily_forecast",
      "placed_order",
      "ideal_incoming",
      "incoming_orders",
      "intransit_orders",
      "ideal_order_qty",
      "remaining",
      "min_qty_in_mfc",
      "opening_inventory",
      "total_inventory",
      "ending_days_cover",
      "lost_sales",
    ].map((key) => {
      return update(
        newObj,
        `${key}[${format(new Date(val.date), "dd-MMM-yy")}]`,
        () => val[key]
        // ? val[key] * gs_price_per_piece
        // : val[key] * UoM
      );
    })
  );

  const excluded = ["date"];
  const rowsDef = Object.entries(newObj).flatMap(([key, val]) =>
    !excluded.includes(key) ? { key_figure: startCase(key), ...val } : []
  );

  //Sum by WEEK
  if (aggregationInverval === "week") {
    const weekRowsDef = rowsDef.map((obj) => {
      const dates = Object.keys(obj).filter((key) => key !== "key_figure");
      const groupedDates = groupBy(dates, (x) => getISOWeek(new Date(x)));
      // Calculate the sum for each group
      const sums = map(groupedDates, (group) => sumBy(group, (date) => obj[date] || 0));
      const weekObject = { key_figure: obj.key_figure };
      sums.map(
        (x, i) =>
          (weekObject[`week_${getISOWeek(addWeeks(new Date(new Date(aggregationDate[0])), i))}`] =
            obj.key_figure === "Ending Days Cover"
              ? numeral(Math.ceil(x)).format("0,0")
              : isCbm
              ? numeral(x).format("0,0.00")
              : numeral(Math.ceil(x)).format("0,0"))
      );
      return weekObject;
    });

    // const weekRange = Object.keys(weekRowsDef[0]).length - 1;
    const weeks = Object.keys(weekRowsDef[0]).filter((key) => key !== "key_figure");
    const allWeeks = weeks.map((week, i) => ({
      field: `week_${getISOWeek(addWeeks(new Date(new Date(aggregationDate[0])), i))}`,
      headerName: `Week ${getISOWeek(addWeeks(new Date(new Date(aggregationDate[0])), i))}`,
      width: "150px",
      cellStyle: () => ({ textAlign: "right", paddingRight: "1rem" }),
      cellRenderer: ({ data, value }) => {
        // manipulated table data as per actual planned result
        const previewsWeek = getISOWeek(new Date()) > week.split(`week_`)[1];
        if (data.key_figure === "Demand" && previewsWeek)
          return weekRowsDef.find((x) => x.key_figure === "Placed Order")[week];
        if (data.key_figure === "Ideal Incoming" && previewsWeek) return 0;
        if (data.key_figure === "Incoming Orders" && previewsWeek) return 0;
        if (data.key_figure === "Intransit Orders" && previewsWeek) return 0;

        if (data.key_figure === "Remaining") {
          // find last working day (with value)
          const remainingOfLastDay = [6, 5, 4, 3, 2, 1].flatMap(
            (day) =>
              rowsDef.find((x) => x.key_figure === "Remaining")[
                getLastDayWeek(week.split(`week_`)[1], day)
              ]
          )[0];
          return numeral(Math.ceil(remainingOfLastDay)).format("0,0");
        }
        if (data.key_figure === "Min Qty In Mfc") {
          const minQty = [6, 5, 4, 3, 2, 1].flatMap(
            (day) =>
              rowsDef.find((x) => x.key_figure === "Min Qty In Mfc")[
                getLastDayWeek(week.split(`week_`)[1], day)
              ]
          )[0];
          return numeral(Math.ceil(minQty)).format("0,0");
        }
        if (data.key_figure === "Opening Inventory" || data.key_figure === "Total Inventory") {
          // find first working day (with value)
          const opening = [1, 2, 3, 4, 5, 6].flatMap(
            (day) =>
              rowsDef.find((x) => x.key_figure === "Opening Inventory")[
                getFirstDayWeek(week.split(`week_`)[1], day)
              ] || []
          )[0];
          return numeral(Math.ceil(opening)).format("0,0");
        }
        if (data.key_figure === "Ending Days Cover") {
          const ending_days = [6, 5, 4, 3, 2, 1].flatMap(
            (day) =>
              rowsDef.find((x) => x.key_figure === "Ending Days Cover")[
                getLastDayWeek(week.split(`week_`)[1], day)
              ] || []
          )[0];
          return numeral(Math.ceil(ending_days)).format("0,0");
        }
        return value; //no change made
      },
    }));
    const weekColumnDef = [
      {
        field: "key_figure",
        headerName: "Key Figure",
        pinned: true,
        width: "175px",
      },
      ...allWeeks,
    ];
    return { rowsDef: weekRowsDef, columnDef: weekColumnDef };
  }
  //Sum by MONTHS
  if (aggregationInverval === "month") {
    const monthRowsDef = rowsDef.map((obj) => {
      const dates = Object.keys(obj).filter((key) => key !== "key_figure");
      const groupedMonths = groupBy(dates, (x) => getMonth(new Date(x)));
      // while (groupedDates.length < 5) groupedDates.push([]);
      // Calculate the sum for each group
      const sums = map(groupedMonths, (group) => sumBy(group, (date) => obj[date] || 0));
      const monthObject = { key_figure: obj.key_figure };
      sums.map(
        (x, i) =>
          (monthObject[format(addMonths(new Date(new Date(aggregationDate[0])), i), "MMMM yyyy")] = x)
      );
      return monthObject;
    });
    // const monthRange = Object.keys(monthRowsDef[0]).length - 1;
    const months = Object.keys(monthRowsDef[0]).filter((key) => key !== "key_figure");
    const allMonths = months.map((month, i) => ({
      field: format(addMonths(new Date(new Date(aggregationDate[0])), i), "MMMM yyyy"),
      headerName: format(addMonths(new Date(new Date(aggregationDate[0])), i), "MMMM yyyy"),
      width: "150px",
      cellStyle: () => ({ textAlign: "right", paddingRight: "1rem" }),
      cellRenderer: ({ data, value }) => {
        // manipulated table data as per actual planned result
        const targetMonth = lastDayOfMonth(addMonths(new Date(new Date(aggregationDate[0])), i));
        const previewsMonth =
          !isSameMonth(targetMonth, new Date()) && differenceInMonths(new Date(), targetMonth) >= 0;
        if (data.key_figure === "Demand" && previewsMonth) {
          const value = monthRowsDef.find((x) => x.key_figure === "Placed Order")[month];
          return numeral(Math.ceil(value)).format("0,0");
        }
        if (data.key_figure === "Ideal Incoming" && previewsMonth) return 0;
        if (data.key_figure === "Incoming Orders" && previewsMonth) return 0;
        if (data.key_figure === "Intransit Orders" && previewsMonth) return 0;

        if (data.key_figure === "Remaining") {
          const remainingOfLastDay = [...Array(31)].flatMap(
            (_, day) =>
              rowsDef.find((x) => x.key_figure === "Remaining")[getLastDayMonth(targetMonth, day)] || []
          )[0];
          return numeral(Math.ceil(remainingOfLastDay)).format("0,0");
        }
        if (data.key_figure === "Min Qty In Mfc") {
          const minQty = [...Array(31)].flatMap(
            (_, day) =>
              rowsDef.find((x) => x.key_figure === "Min Qty In Mfc")[
                getLastDayMonth(targetMonth, day)
              ] || []
          )[0];
          return numeral(Math.ceil(minQty)).format("0,0");
        }
        if (data.key_figure === "Opening Inventory" || data.key_figure === "Total Inventory") {
          const opening = [...Array(31)].flatMap(
            (_, day) =>
              rowsDef.find((x) => x.key_figure === "Opening Inventory")[
                getFirstDayMonth(targetMonth, day)
              ] || []
          )[0];
          return numeral(Math.ceil(opening)).format("0,0");
        }
        if (data.key_figure === "Ending Days Cover") {
          const ending_days = [...Array(31)].flatMap(
            (_, day) =>
              rowsDef.find((x) => x.key_figure === "Ending Days Cover")[
                getLastDayMonth(targetMonth, day)
              ] || []
          )[0];
          return numeral(Math.ceil(ending_days)).format("0,0");
        }
        return isCbm ? numeral(value).format("0,0.00") : numeral(Math.ceil(value)).format("0,0");
      },
    }));
    const monthColumnDef = [
      {
        field: "key_figure",
        headerName: "Key Figure",
        pinned: true,
        width: "175px",
      },
      ...allMonths,
    ];
    return { rowsDef: monthRowsDef, columnDef: monthColumnDef };
  }
  return { rowsDef, columnDef };
};

export const downloadAggregation = (data, filename) => {
  const { setIsLoading } = aggregationAction;
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      generateJsonToCSV(data, filename);
      dispatch(setIsLoading());
    } catch (error) {
      dispatch(setIsLoading());
      checkError(error);
    }
  };
};

const getLastDayWeek = (weekNumber, add_day = 6) => {
  const currentYear = new Date().getFullYear();
  const firstDayOfWeek1 = startOfWeek(new Date(currentYear, 0, 1), { weekStartsOn: 0 });
  const lastDayOfWeek = addDays(firstDayOfWeek1, (weekNumber - 1) * 7 + add_day);
  return format(lastDayOfWeek, "dd-MMM-yy");
};

const getFirstDayWeek = (weekNumber, add_day = 1) => {
  const currentYear = new Date().getFullYear();
  const firstDayOfWeek1 = startOfWeek(new Date(currentYear, 0, 1), { weekStartsOn: 0 });
  const firstDayOfWeekNow = addDays(firstDayOfWeek1, (weekNumber - 1) * 7 + add_day);
  return format(firstDayOfWeekNow, "dd-MMM-yy");
};

const getLastDayMonth = (targetMonthLastDay, add_day = 0) => {
  const lastDayMonth = addDays(targetMonthLastDay, -add_day);
  return format(lastDayMonth, "dd-MMM-yy");
};

const getFirstDayMonth = (targetMonth, add_day) => {
  const firstDayOfMonth1 = startOfMonth(targetMonth);
  const firstDayOfMonth = addDays(firstDayOfMonth1, add_day);
  return format(firstDayOfMonth, "dd-MMM-yy");
};

export default getAggregationData;
