import React, { useLayoutEffect } from "react";
import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import getForecast from "../../../store/forecast/forecast-actions";
import { forecastState } from "../../../store/forecast/forecast-slice";
import { datatableState } from "../../../store/datatable/datatable-slice";
import SkuForecast from "../forecast/SkuForecast";
import { flatMap } from "lodash";

const ForeCastList = () => {
  const dispatch = useDispatch();
  const { forecastData, isLoading } = useSelector(forecastState);
  const { selectedItem } = useSelector(datatableState);

  useLayoutEffect(() => {
    const allSelectedItem = flatMap(Object.values(selectedItem));
    dispatch(getForecast(allSelectedItem));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {/* <ProgressBar loading={isLoading} /> */}

      {forecastData.length ? (
        forecastData.map((data, i) => <SkuForecast key={i} data={data} />)
      ) : (
        <Row pt={3} sx={{ display: isLoading && "none", fontWeight: 600 }}>
          No report found.
        </Row>
      )}
    </>
  );
};

export default ForeCastList;
