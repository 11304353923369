// import { HomeOutlined, SettingOutlined } from "@ant-design/icons";

export const adminNavList = [
  {
    path: "/home",
    label: "Home",
    // icon: <HomeOutlined />,
  },
  {
    path: "/sync_status",
    label: "Sync Status",
    // icon: <HomeOutlined />,
  },
  {
    path: "/explore",
    label: "Explore",
    // icon: <HomeOutlined />,
  },
  {
    path: "/configs",
    label: "Config Management",
    submenu: [
      // {
      //   path: "/manage_sku",
      //   label: "Manage SKU's",
      //   // icon: <SettingOutlined />,
      // },
      {
        path: "/manage_vendors",
        label: "Manage Vendors",
        // icon: <SettingOutlined />,
      },
      {
        path: "/manage_accounts",
        label: "Manage Accounts",
        // icon: <SettingOutlined />,
      },
    ],
  },
];

const services = [
  {
    code: "home",
    name: "Home",
    link: "/admin/",
    nav: adminNavList,
  },
  {
    code: "explore",
    name: "Explore",
    link: "/explore",
    nav: adminNavList,
  },
  {
    code: "supply-plan",
    name: "SUPPLY DEMAND",
    link: "/admin/supply-plan/",
    nav: adminNavList,
  },
  {
    code: "forecast",
    name: "Forecast",
    link: "/admin/forecast",
    nav: adminNavList,
  },
  {
    code: "configs",
    name: "Config Management",
    link: "/admin/configs",
    nav: adminNavList,
  },
];

export default services;
