import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  warehouseList: [],
  isLoading: true,
};

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    setWarehouseList(state, { payload }) {
      state.warehouseList = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export default warehouseSlice.reducer;
export const warehouseAction = warehouseSlice.actions;
export const warehouseState = (state) => state.warehouse;
