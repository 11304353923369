import React, { useEffect, useLayoutEffect, useState } from "react";
import Aggregations from "../organisms/aggregation/Aggregations";
import { useDispatch } from "react-redux";
import { clearSessionData } from "../../store/ui/ui-actions";
import { Prompt } from "react-router-dom";

const ExplorePage = () => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(clearSessionData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault(); // Cancel the default event behavior
      event.returnValue = ""; // Chrome requires a returnValue to be set
    };
    const handleUnload = () => {
      return "Are you sure you want to leave this page?"; // The message displayed in the dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  const [isBlocking, setIsBlocking] = useState(false);
  useEffect(() => {
    setIsBlocking(true);
    return () => setIsBlocking(false);
  }, []);

  return (
    <>
      <Prompt
        when={isBlocking}
        message="Are you sure you want to leave? Your changes may not be saved."
      />
      <Aggregations />
    </>
  );
};

export default ExplorePage;
