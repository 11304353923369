import { useState } from "react";
import { snakeCase } from "lodash";

export const useForm = (initialState = {}, type) => {
  const [formData, setFormData] = useState(initialState);

  const onChange = (props, label) => {
    if (type === "select") {
      console.log({ [snakeCase(label)]: props });
    } else {
      const {
        target: { name, value },
      } = props;
      setFormData({ ...formData, [snakeCase(name)]: value });
    }
  };

  return { formData, onChange };
};
