import { API_URI } from "../../config";
import { checkError, requestOption } from "../api/request";
import { warehouseAction } from "./warehouse-slice";

const getWarehouseList = () => {
  return async (dispatch) => {
    const { setWarehouseList, setIsLoading } = warehouseAction;
    try {
      dispatch(setIsLoading(true));
      const { data } = await requestOption(`${API_URI}/admin/v1/warehouse`, false, { refetch: false });
      const serialize = data.map((x) => ({ ...x, value: x.value || x.name, label: x.label || x.name }));
      dispatch(setWarehouseList(serialize));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      checkError(error);
    }
  };
};

export default getWarehouseList;
