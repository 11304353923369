import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import GsSelect from "../../atoms/customForm";
import { useDispatch, useSelector } from "react-redux";
import { filter, find, isArray, pickBy, size, snakeCase, sortBy } from "lodash";
import getCategories from "../../../store/categories/categories-actions";
import { categoriesState } from "../../../store/categories/categories-slice";
import { vendorState } from "../../../store/vendors/vendor-slice";
import getVendorList from "../../../store/vendors/vendor-actions";
import { major_categoriesState } from "../../../store/major_categories/major_categories-slice";
import getMajorCategories from "../../../store/major_categories/major_categories-actions";
import { aggregationAction, aggregationState } from "../../../store/aggregation/aggregation-slice";
import { generateAggregationTable } from "../../../store/aggregation/aggregation-actions";
import { AgGridReact } from "ag-grid-react";
import { getRowStyle } from "../../../store/forecast/forecast-actions";

const AggregationFilters = ({ warehouse }) => {
  const dispatch = useDispatch();
  const { vendorList } = useSelector(vendorState);
  const { categoryList } = useSelector(categoriesState);
  const { major_categoryList } = useSelector(major_categoriesState);

  const [formData, setFormData] = useState({
    major_category: [],
    quadrant: [],
    vendor: [],
    source_type: [],
    category: [],
  });
  const onChange = (value) => setFormData({ ...formData, ...value });

  useEffect(() => {
    if (!warehouse) return;
    dispatch(getVendorList(warehouse));
    !categoryList.length && dispatch(getCategories(warehouse));
    !major_categoryList.length && dispatch(getMajorCategories(warehouse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse]);

  return (
    <>
      <Row align="top" gutter={6}>
        <Col flex="225px">
          <GsSelect
            label="Major Category"
            options={major_categoryList.map((c) => ({ label: c, value: c }))}
            onChange={onChange}
            style={{ marginBottom: "1rem" }}
            width="215px"
            multiple
            defaultValue={formData.major_category}
          />
        </Col>
        <Col flex="190px">
          <GsSelect
            label="quadrant"
            options={["Q1", "Q2", "Q3", "Q4", "Uncategorized"].map((x) => ({ value: x, label: x }))}
            onChange={onChange}
            multiple
            width="180px"
            defaultValue={formData.quadrant}
          />
        </Col>
        <Col flex="200px">
          <GsSelect
            label="Vendor"
            width="200px"
            // options={sortBy(
            //   vendorList.map(({ label, id }) => ({ label, value: `${label}_${id}` })),
            //   ["label"]
            // )}
            options={sortBy(
              vendorList.map(({ label, id }) => ({ label, value: `${id}^${label}` })),
              ["label"]
            )}
            onChange={onChange}
            multiple
            value={formData.vendor}
          />
        </Col>
        <Col flex="190px">
          <GsSelect
            label="source_type"
            value={formData.source_type}
            // options={["DTS", "CH"].map((x) => ({ value: snakeCase(x), label: x }))}
            options={["DTS", "VCH", "SCH"].map((x) => ({ value: snakeCase(x), label: x }))}
            onChange={onChange}
            multiple
            width="190px"
          />
        </Col>
        <Col flex="180px">
          <GsSelect
            label="category"
            options={categoryList.map((c) => ({ label: c, value: c }))}
            onChange={onChange}
            multiple
            width="170px"
            value={formData.category}
          />
        </Col>
      </Row>
      <FilterResult filters={formData} />
    </>
  );
};

const FilterResult = ({ filters }) => {
  console.log("filters", filters);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const { aggregationObj, aggregationInverval, aggregationUom, aggregationDate } = useSelector(
    aggregationState
  );
  const { vendorList } = useSelector(vendorState);

  useEffect(() => {
    if (!data.length) return setRowData([]);
    const { rowsDef, columnDef } = generateAggregationTable(
      data,
      aggregationDate,
      aggregationUom,
      aggregationInverval,
      aggregationUom === "cbm"
    );
    setColumnDefs(columnDef);
    setRowData(rowsDef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregationInverval, data]);

  useEffect(() => {
    Object.values(aggregationObj).length && setData(Object.values(aggregationObj));
  }, [aggregationObj]);

  useEffect(() => {
    const originalArr = Object.values(aggregationObj);
    if (!originalArr.length) return;

    const filteredBy = Object.keys(pickBy(filters, (value) => isArray(value) && size(value) > 0));
    if (!filteredBy.length) {
      dispatch(aggregationAction.setAggregationFilters([]));
      return setData(Object.values(aggregationObj));
    }

    const filterArrays = () => {
      const filterBy = (params = "quadrant", arr = []) => {
        const target = filters[params].map((x) => snakeCase(x));

        if (params === "major_category") {
          return filter(arr, (o) => target.includes(snakeCase(o.sku["mega_category"])));
        }
        if (params === "vendor") {
          const target_vendor = filters[params].map((x) => x);
          console.log("target_vendor", target_vendor);
          const vendor_code = target_vendor.map(
            // (x) => find(vendorList, ({ id }) => +id === +x.split("_")[1])?.code
            (v) => find(vendorList, (x) => x.id === +v.split("^")[0])?.vendor_code
          );
          return filter(arr, (o) => vendor_code.includes(o.sku["vendor_code"]));
        }
        return filter(arr, (o) => target.includes(snakeCase(o.sku[params])));
      };

      let temp = [];
      filteredBy.map((params) => {
        temp = !temp.length ? filterBy(params, originalArr) : filterBy(params, temp);
        return temp;
      });
      return temp || [];
    };
    const filterName = filteredBy.map((x) => `${x}:${filters[x].join(",")}`);
    dispatch(aggregationAction.setAggregationFilters(filterName));
    console.log(filterArrays());
    setData(filterArrays());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, aggregationUom]);

  useEffect(() => {
    if (!rowData.length) return;
    dispatch(aggregationAction.setAggregationResult(rowData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  return columnDefs.length ? (
    <Row gutter={8} style={{ padding: "1rem 0.5rem" }}>
      <Col className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          enableCellChangeFlash={true}
          enableColResize={true}
          enableSorting={true}
          rowHeight={30}
          overlayNoRowsTemplate={`<div class="c3 tx90 tx400" >Filtered Results: No matches found.</div>`}
          getRowStyle={getRowStyle}
          groupUseEntireRow={true}
          containerStyle={{ height: (rowData.length ? 2.1 * rowData.length : 13) + "rem" }}
          className={`ag-theme-balham`}
        />
        <Col style={{ padding: "1rem 0.5rem", textAlign: "end" }}>
          <h4 style={{ paddingLeft: "0.25rem" }} className="c3">
            Filtered SKU's:
            <span className="c3 tx600 tx90" style={{ margin: "0 0.5rem" }}>
              {data.length}
            </span>
          </h4>
        </Col>
      </Col>
    </Row>
  ) : (
    <Row className="c3" style={{ padding: "1rem 0.5rem" }}>
      No aggregation created yet.
    </Row>
  );
};

// const AggregationReports = () => {
//   return
// }

export default AggregationFilters;
