import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { datatableAction } from "../../../store/datatable/datatable-slice";
import { simulationAction } from "../../../store/simulation/simulation-slice";
import { GsButton } from "../../atoms/customForm";

const { setActiveSimulation } = simulationAction;

const ViewDetails = ({ row }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const viewInstance = () => {
    dispatch(datatableAction.pageReset());
    dispatch(
      setActiveSimulation({
        simulation_id: row.request_id,
        simulation_name: row.name,
        mfc: row.warehouse_name,
        vendor_code: row.vendor_code,
      })
    );

    history.push("/supply_plan/" + row.name);
  };

  return (
    <>
      <GsButton
        label={<b style={{ fontWeight: 500 }}>View Details</b>}
        onClick={viewInstance}
        type="text"
        style={{ padding: "0.25rem", width: "150px" }}
        offset={0}
      />
    </>
  );
};

export default ViewDetails;
