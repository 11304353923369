import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { authState } from "../../store/auth/auth-slice";
import loginAuth from "../../store/auth/auth-actions";
import { Col, Row } from "antd";
import { GsButton, GsInput } from "../atoms/customForm";
import { useForm } from "../../utils/useForm";
import warehouseImg from "../../assets/warehouse-full.png";
import { ProgressBar } from "../atoms/ProgressBar";

const AuthForm = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(authState);
  const { formData, onChange } = useForm({ username: "", password: "" });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(loginAuth(formData));
  };
  return (
    <Row justify="center" align="middle" style={{ height: "90vh" }}>
      <Col gutter={24} style={{ marginBottom: "2rem", alignItems: "flex-start", width: "250px" }}>
        <Col>
          <Row justify="center">
            <Col span={24}>
              <Row justify="center">
                <img width="140px" src={warehouseImg} alt="growsari" />
              </Row>
            </Col>
            <Col className="tx600 tx125 c1" style={{ paddingBottom: "1rem", marginTop: "-1rem" }}>
              MFC Admin
            </Col>
          </Row>
        </Col>
        <GsInput
          label="Username"
          onChange={onChange}
          style={{ marginBottom: "0.25rem", width: "100%" }}
        />
        <GsInput
          label="Password"
          onChange={onChange}
          style={{ marginBottom: "0.25rem", width: "100%" }}
          type="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        />

        <GsButton
          label="Confirm"
          onClick={onSubmit}
          type="primary"
          style={{ marginLeft: "-0.15rem", marginTop: "0.75rem", padding: "0.75rem", width: "100%" }}
        />

        <ProgressBar show={isLoading} />
      </Col>
    </Row>
  );
};

export default AuthForm;
