import { Row, Spin } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";

export const ProgressBar = ({ show, style = {}, size = "default", offset }) => {
  const { pathname } = useLocation();
  return (
    <Row
      style={{
        height: "100vh",
        position: "absolute",
        width: "-webkit-fill-available",
        justifyContent: "center",
        alignItems: "center",
        display: !show && "none",
        top: 0,
        zIndex: 1,
        ...style,
      }}
    >
      <Spin
        tip={pathname === "/explore" ? "Please wait while we prepare the data" : "Loading"}
        style={{ zIndex: 2, paddingLeft: offset || "0.25rem" }}
        size={size}
      />
    </Row>
  );
};

export const Spinner = ({ height, show }) => {
  return show ? (
    <Row
      style={{
        height: height || "100vh",
        position: "absolute",
        width: "-webkit-fill-available",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin style={{ zIndex: 2 }} />
    </Row>
  ) : null;
};

export default ProgressBar;
