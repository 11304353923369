import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showNotification: false,
  notificationType: "success",
  notification: "Notification message",
  autoHideDuration: 3000,
  previousPage: '/home'
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setShowNotification(state, { payload }) {
      state.showNotification = payload;
    },
    setNotification(state, { payload }) {
      state.notification = payload.message;
      state.notificationType = payload.type;
      state.showNotification = true;
    },
    resetNotification(state) {
      state.showNotification = false;
    },
    setPreviousPage(state, { payload }) {
      state.previousPage = payload;
    },
  },
});

export default uiSlice.reducer;
export const uiAction = uiSlice.actions;
export const uiState = (state) => state.ui;
