import { API_URI } from "../../config";
import { checkError, requestOption } from "../api/request";
import { categoriesAction } from "./categories-slice";

const getCategories = (mfc) => {
  return async (dispatch) => {
    const { setCategoryList } = categoriesAction;
    try {
      const { data } = await requestOption(`${API_URI}/admin/v1/category?warehouse_code=${mfc}`, false, {
        refetch: false,
      });
      dispatch(setCategoryList(data));
    } catch (error) {
      checkError(error);
    }
  };
};

export default getCategories;
