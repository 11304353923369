import { Button, Modal, Row, Tooltip } from "antd";
import React from "react";
import { modal } from "../../utils/style.module.less";
import { CloseIcon } from "../../assets/icons";

const ModalBox = ({ showModal, setShowModal, content = "Content", closable }) => {
  const handleOk = () => setShowModal(false);
  const handleCancel = () => setShowModal(false);
  const Icon = () => (
    <Tooltip title="Close">
      <Button style={{ margin: "1rem 0.25rem 0 0" }} type="link" shape="circle" icon={<CloseIcon />} />
    </Tooltip>
  );

  return (
    <Modal
      centered
      title={false}
      footer={false}
      open={showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      width="max-content"
      className={modal}
      closeIcon={<Icon />}
      closable={closable}
    >
      <Row style={{ minHeight: "150px" }}>{content}</Row>
    </Modal>
  );
};

export default ModalBox;
