import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendorList: [],
  isLoading: true,
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setVendorList(state, { payload }) {
      state.vendorList = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export default vendorSlice.reducer;
export const vendorAction = vendorSlice.actions;
export const vendorState = (state) => state.vendor;
