import { forecastAction } from "./forecast-slice";
import { checkError, requestOption } from "../api/request";
import { isNumber, map, snakeCase } from "lodash";
import { format } from "date-fns";
import { API_URI } from "../../config";
import React from "react";
import { isNaN, mapValues, join } from "lodash";
import { getForage } from "../api/forage";
import { columnHeader, generateDates } from "../../components/organisms/forecast/options";
import { groupByKey } from "../../utils/helper";
import numeral from "numeral";
import { deliveryDaysFormat, getNextTargetDays, transformData } from "./helperFunc";
import sendNotification from "../../utils/sendNotification";
import getVendorList from "../vendors/vendor-actions";
import Tooltip from "antd/es/tooltip";

export const getRowStyle = ({ data: { key_figure } }) => {
  if (key_figure.match(/total supply/i)) {
    return { background: "#ffb84d", fontWeight: "bold" };
  }
  if (key_figure.match(/^demand$/i)) {
    return { background: "#5cead9", fontWeight: "bold" };
  }
  if (key_figure.match(/remaining|ending days/i)) {
    return { fontWeight: "bold" };
  }
  if (key_figure.match(/lost sales|over stock/i)) {
    return { fontWeight: "bold", fontStyle: "oblique" };
  }
};

export const recalculatePlan = async (payload, onComplete) => {
  try {
    const { data: collection } = await requestOption(`${API_URI}/admin/v1/generate_plan`, payload);

    const data = Object.values(collection)
      .map(({ demand, sku }) => {
        const { name, case_config, cbm_per_case, max_days_cover, min_qty_in_mfc, max_qty_in_mfc, id } = sku;
        return demand.map((d) => ({
          ...d,
          case_config,
          cbm_per_case,
          max_stock_qty: max_days_cover,
          min_stock_qty: min_qty_in_mfc,
          max_qty_in_mfc,
          name,
          sku_id: id,
          total_inventory: +d.total_supply + +d.opening_inventory,
        }));
      })
      .flat();
    onComplete();
    const updatedPO = calcSDF("placeOrderCheck", data);
    sendNotification("Success!", "SKU plan updated successfully", "success");
    return Object.values(transformData(updatedPO));
  } catch (error) {
    onComplete();
    checkError(error);
  }
};

const getForecast = (sku_ids = [], mfc) => {
  const { setForecastData, setForecastColumn, setIsLoading } = forecastAction;
  return async (dispatch) => {
    const vendors = await dispatch(getVendorList(mfc));

    try {
      if (!sku_ids?.length) return dispatch(setForecastData([]));

      dispatch(setIsLoading(true));
      const { data: collection } = await requestOption(`${API_URI}/admin/v1//sku_plan?sku_id=${sku_ids}`, false, {
        record: false,
      });
      function addPrefix(string) {
        const hasPipe = string?.delivery_day.includes("|");
        if (hasPipe) {
          const weekdays = string.delivery_day.split("|");
          const weekdaysWithPrefix = map(weekdays, (day) => `0/${day}`);
          const outputString = join(weekdaysWithPrefix, ",");
          return outputString;
        } else {
          return string.delivery_day;
        }
      }
      const data = Object.values(collection)
        .map(({ demand, sku }) => {
          const {
            name,
            case_config,
            cbm_per_case,
            // max_days_cover,
            // min_qty_in_mfc,
            max_qty_in_mfc,
            id,
            planning_status,
            updated_at,
            gs_price_per_piece,
          } = sku;

          return demand.map((d) => ({
            ...d,
            case_config,
            cbm_per_case,
            max_stock_qty: max_qty_in_mfc,
            min_stock_qty: d.min_qty_in_mfc,
            max_qty_in_mfc,
            name,
            vendor_name: vendors.find((v) => v.code === d.vendor_code)?.name,
            delivery_day: addPrefix(vendors.find((v) => v.code === d.vendor_code)),
            sku_id: id,
            planning_status,
            updated_at,
            gs_price_per_piece,
            total_inventory: +d.total_supply + +d.opening_inventory,
          }));
        })
        .flat();
      const updatedPO = calcSDF("placeOrderCheck", data);
      const collectedSKU = Object.values(groupByKey(updatedPO, "sku_code"));

      const column = [
        ...columnHeader,
        ...generateDates(format(new Date(data[0]?.date || new Date()), "yyyy-MM-dd"), 28),
      ];

      dispatch(setForecastColumn(column));

      await dispatch(setForecastData(collectedSKU));

      setTimeout(() => {
        dispatch(setIsLoading(false));
      }, 300);
    } catch (error) {
      await dispatch(setForecastData([]));
      dispatch(setIsLoading(false));
      checkError(error, dispatch);
    }
  };
};

export const updateUom = (arr, multiplier, peso, isCBM) => {
  return map(arr, (obj) =>
    mapValues(obj, (val) => {
      const placedOrderVal = obj.key === "placed_order" && !isNaN(+val[0]);
      const isLostSales = obj.key === "lost_sales" && !isNaN(+val);
      const isOverStock = obj.key === "over_stock" && !isNaN(+val);
      const isEndingDaysCover = obj.key === "ending_days_cover" && !isNaN(+val);
      const isAdjustment =
        ["demand_adjustment", "supply_adjustment"].includes(obj.key) &&
        !["Demand Adjustment", "demand_adjustment", "Supply Adjustment", "supply_adjustment"].includes(val);
      if (isLostSales) return Math.ceil(val * peso);
      if (isEndingDaysCover) return Math.ceil(val);
      if (isAdjustment) return +val === 0 ? null : Math.ceil(val * multiplier);
      if (isOverStock) return val === 1 ? "True" : "False";
      if (placedOrderVal) {
        return `${Math.ceil(+val?.split("_")?.[0] * multiplier)}_${val?.split("_")?.[1]}_${val?.split("_")?.[2]}`;
      } else {
        return val === null
          ? null
          : isNaN(+val)
          ? val
          : isCBM
          ? (val * multiplier).toFixed(2)
          : Math.ceil(val * multiplier);
      }
    })
  );
};

export const calcSDF = (type, arr) => {
  const getValue = {
    placeOrderCheck: () => {
      const collection = map(arr, (x) => {
        // const editableSupply = `${x.supply_adjustment}_${x.delivery_day}`;
        const updatedPlacedOrded = +x.placed_order > +x.forecast ? x.placed_order + "_red" : x.placed_order + "_green";
        return {
          ...x,
          placed_order: updatedPlacedOrded + `_${x.delivery_day}`,
          // supply_adjustment: editableSupply,
        };
      });
      return collection;
    },
  };
  return getValue[type]();
};

export const addTableStyle = async (column = [], revisionMode = true, vendor_code, warehouse_code) => {
  const vendors = await getForage("vendor");
  const deliveryDays = deliveryDaysFormat(vendors.find(({ code }) => +code === +vendor_code));
  const dateList = getNextTargetDays(deliveryDays.split(","));
  const editableCellRenderer = ({ data, value }, revisionMode) => {
    const isPlaceOrder = data.key_figure.match(/^placed order/i) && value?.match(/red|green/i);

    const remainInventory = data.key_figure.match(/remaining inventory/i) && value < 0;
    if (isPlaceOrder) {
      return (
        <span
          style={{
            color: value?.match(/red/i) ? "red" : "inherit",
            fontWeight: value?.match(/red/i) ? "bolder" : "inherit",
          }}
        >
          {isNumber(value.split("_")[0]) ? numeral(value.split("_")[0]).format("0,0") : value.split("_")[0]}
        </span>
      );
    }
    if (remainInventory) {
      return <span style={{ color: "red" }}>{isNumber(value) ? numeral(value).format("0,0") : value}</span>;
    }
    if (data.key_figure?.match(/bulk order qty*y$/i)) {
      return (
        <span>
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        </span>
      );
    }
    if (data.key_figure?.match(/demand adjustment|supply adjustment/i)) {
      return (
        <span style={{ color: revisionMode ? "grey" : "inherit" }}>
          {isNumber(value) ? numeral(value).format("0,0") : value}
        </span>
      );
    }
    return <span>{isNumber(value) ? numeral(value).format("0,0") : value}</span>;
  };

  const styleTable = column.reduce((accumulator, current) => {
    return [
      ...accumulator,
      {
        ...current,
        children: [
          {
            ...current.children[0],
            children: [
              {
                ...current.children[0].children[0],
                editable: ({ data, value, colDef }) => {
                  const supplyAdj = data.key_figure.match(/supply adjustment/i);
                  if (revisionMode) {
                    return !revisionMode;
                  }
                  if (supplyAdj) {
                    const editableSupplyDate = dateList;
                    return editableSupplyDate?.includes(colDef.field);
                  }
                  if (data.key_figure.match(/demand adjustment/i)) {
                    return true;
                  }
                  return revisionMode
                    ? data.key_figure.match(/demand adjustment/i) &&
                        !["wtd_offtake", "key_figure"].includes(current.children[0].children[0].field)
                    : false;
                },
                cellStyle: ({ data, colDef }) => {
                  const supplyAdj = data.key_figure.match(/supply adjustment/i);
                  if (revisionMode && data.key_figure.match(/demand adjustment|supply adjustment/i)) {
                    return {
                      background: "#cccccc65",
                      textAlign: isNaN(+colDef.field[0]) ? "left" : "right",
                      marginRight: "1rem",
                    };
                  }
                  if (supplyAdj) {
                    const editableSupplyDate = dateList;
                    return {
                      background:
                        editableSupplyDate?.includes(colDef.field) || isNaN(+colDef.field[0]) ? "inherit" : "#cccccc65",
                      textAlign: isNaN(+colDef.field[0]) ? "left" : "right",
                      marginRight: "1rem",
                    };
                  }
                  const textAlign = isNaN(+current.children[0].children[0].field[0]) ? "left" : "right";
                  const coloredKey =
                    data.key_figure.match(/forecast|^placed order|incoming|intransit|ideal order|opening inventory/i) &&
                    ["wtd_offtake", "key_figure"].includes(current.children[0].children[0].field);
                  return coloredKey
                    ? { color: "#00998f", fontWeight: "bold", textAlign }
                    : { textAlign, marginRight: "1rem" };
                },
                cellRenderer: (props) => editableCellRenderer(props, revisionMode),
              },
            ],
          },
        ],
      },
    ];
  }, []);
  return await styleTable;
};

export const getRSCupdate = ({ warehouse = "QC" }) => {
  return async (dispatch) => {
    const { setRSCupdate } = forecastAction;
    try {
      const { data } = await requestOption(`${API_URI}/admin/v1/rsc_updates?warehouse_code=${warehouse}`, false, {
        refetch: false,
      });

      const result = {};
      data.map((x) => (result[snakeCase(x.data_source)] = format(new Date(x.latest_update), "MMM-dd-yyyy HH:mmaaa")));
      dispatch(setRSCupdate(result));
    } catch (error) {
      checkError(error);
    }
  };
};

export default getForecast;
